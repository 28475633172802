import { GET_CHATS_REQUEST, GET_CHATS_RESPONSE, GET_MEMBER_GROUPS } from '../types';
// import { ReviewStatus } from '../../models/reviews';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case GET_CHATS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_CHATS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        chats: action.payload,
      }
    case GET_MEMBER_GROUPS:
      return {
        isFetching: false,
        state,
        groups: action.payload.items,
      }
    default:
      return state;
  }
};
