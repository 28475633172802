export interface ReviewsContext {
  reviews?: Reviews[];
  getReviews?: Function;
  isFetching?: boolean;
  removeFlaggedMessage?: Function;
  approveFlaggedMessage?: Function;
}

export interface Reviews {
  id: string;
  reviewType: string;
  programId: string;
  entity: Entity;
  status: string;
  createDate: string;
}

export interface Entity {
  id: string;
  name?: string;
  postType?: string;
  programId?: string;
  eventId?: string;
}

export enum ReviewType {
  member = 'member',
  post = 'post',
  event = 'event',
  message = 'message',
}

export enum ReviewStatus {
  pending = 'pending',
  approved = 'approved',
  banned = 'banned',
}