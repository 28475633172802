import React, { useContext, Fragment, useEffect } from 'react';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../models/auth';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import firebase from 'firebase';

const SubMenu: any = Menu.SubMenu;

const Nav = () => {
  const authContext: AuthContext = useContext(_authContext);
  const setNavActiveTab = authContext.setNavActiveTab!;
  const { navActiveTab } = authContext;

  const { user } = authContext;

  // const tabs = ['reviews', 'programs', 'members', 'events', 'posts', 'chat', 'lookups', 'admins'];
  const tabs = ['members', 'events', 'posts', 'chat', 'lookups', 'admins'];

  useEffect(() => {
    const path = window.location.pathname;
    let tabSet = false;
    tabs.forEach(i => {
      if (path.includes(i)) {
        tabSet = true
        setNavActiveTab(i);
      }
    });
    if (!tabSet) {
      setNavActiveTab(tabs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e: any) => {
    setNavActiveTab(e.key);
  };

  const logOut = async () => {
    await firebase.auth().signOut();
    window.location.reload();
  };


  const navBar = (
    <Menu
      onClick={handleClick}
      selectedKeys={[navActiveTab!]}
      mode="horizontal"
      style={{ display: 'flex', justifyContent: 'center' }}>
      {/* <Menu.Item key="reviews">
        <Link to="/reviews">
          <Icon type="bell" />
          Reviews
        </Link>
      </Menu.Item> */}
      {/* <Menu.Item key="programs">
        <Link to="/programs">
          <Icon type="schedule" />
          Programs
        </Link>
      </Menu.Item> */}
      <Menu.Item key="members">
        <Link to="/members">
          <Icon type="team" />
          Members
        </Link>
      </Menu.Item>

      <Menu.Item key="events">
        <Link to="/events">
          <Icon type="calendar" />
          Events
        </Link>
      </Menu.Item>

      <Menu.Item key="jobs">
        <Link to="/jobs">
          <Icon type="solution" />
          Job Posts
        </Link>
      </Menu.Item >
      <Menu.Item key="posts">
        <Link to="/posts">
          <Icon type="solution" />
          Community Posts
        </Link>
      </Menu.Item>
      <Menu.Item key="lookups">
        <Link to="/lookups">
          <Icon type="search" />
          Lookups
        </Link>
      </Menu.Item>
      <Menu.Item key="admins">
        <Link to="/admins">
          <Icon type="user" />
          Admins
        </Link>
      </Menu.Item>
      <SubMenu
        trigger={['click']}
        style={{ position: 'absolute', right: '5px' }}
        title={
          <Icon
            type="user"
            style={{ fontSize: '30px', margin: '5px 0px 0px' }}
          />
        }>
        <Menu.Item key="user" disabled>
          <Icon type="user" />
          {user && user.username ? `${user.username}` : ''}
        </Menu.Item>
        <Menu.Item key="logout" onClick={logOut}>
          <Link to="/">
            <Icon type="logout" />
            Logout
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu >
  );

  return <Fragment>{user ? navBar : ''}</Fragment>;
};

export default Nav;
