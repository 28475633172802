import React, { useContext, useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { PostContext } from '../../models/post';
import { postContext as _postContext } from '../../contexts/post/postContext';
import { LookupsContext } from '../../models/lookups';
import { lookupsContext as _lookupContext } from '../../contexts/lookups/lookupsContext';
import PostCreateModal from './PostCreateModal';
import { Table, Button } from 'antd';
import { getAllLookups } from '../../utils/safeproject-api-client';
import { onCSVExport } from '../../utils/utils';
// import eventContext from '../../contexts/event/eventContext';

const PostsLists = () => {
  const [redirectToPost, setRedirectToPost] = useState<any>();
  const postContext: PostContext = useContext(_postContext);
  const lookupsContext: LookupsContext = useContext(_lookupContext);
  const getPosts = postContext.getPosts!;
  const createJobPost = postContext.createJobPost!;
  const { lookupsCollection } = lookupsContext;
  const { posts, isFetching } = postContext;
  const getLookups = lookupsContext.getLookupsFromApi!;
  const [modalVisible, setModalVisible] = useState(false);
  const [infoFormRef, setInfoFormRef] = useState({
    props: {
      form: { validateFields: (cb: Function) => { }, resetFields: () => { } },
    },
  });
  const location = useLocation();
  console.log(location.pathname);
  useEffect(() => {
    getPosts();
    getLookups();

    // return () => {
    //   clearEvent();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = location.pathname === '/jobs' ? [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created On',
      dataIndex: 'createDate',
      key: 'createDate',
    },
    {
      title: 'Created By Name',
      dataIndex: 'createdByUser.name',
      key: 'createdByUserName',
    },
    {
      title: 'Created By Email',
      dataIndex: 'createdByUser.email',
      key: 'createdByUserEmail',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Type',
      dataIndex: 'postType',
      key: 'postType',
      render: (text:any) => {
        const capitalizedType = text.replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) => letter.toUpperCase());
        return <a>{capitalizedType}</a>
      }
    }
  ] : [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created On',
      dataIndex: 'createDate',
      key: 'createDate',
    },
    {
      title: 'Created By Name',
      dataIndex: 'createdByUser.name',
      key: 'createdByUserName',
    },
    {
      title: 'Created By Email',
      dataIndex: 'createdByUser.email',
      key: 'createdByUserEmail',
    },
    {
      title: 'Type',
      dataIndex: 'postType',
      key: 'postType',
      render: (text:any) => {
        const capitalizedType = text.replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) => letter.toUpperCase());
        return <a>{capitalizedType}</a>
      }
    }
  ];

  const dateFormat = (isoTime: any) => {
    let returnDate = new Date(isoTime);
    return `${returnDate.toLocaleDateString('en-US', {
      weekday: 'long',
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    })}`
  }
  const formattedPosts = posts?.map(post => (
    {
      ...post,
      createDate: dateFormat(post.createDate)
    })).filter(post => location.pathname === '/jobs' ? post.postType === 'job opportunity' : post.postType !== 'job opportunity');

  const renderRedirectToPost = () => {
    if (redirectToPost && redirectToPost!.id) {
      return <Redirect to={`/posts/${redirectToPost!.id}`} />;
    }
    return null;
  };
  // const goToBlankStandIn = (random: { name: string; createDate: string; postType: string; }) => {
  //   console.log(random);
  //   if()
  //   // return <Redirect to={'/posts/randompostid'} />
  // }

  const saveInfoFormRef = (formRef: any) => setInfoFormRef(formRef);
  const setInfoModalVisible = (boolVal: React.SetStateAction<boolean>) => {
    setModalVisible(boolVal);
  }
  const handleCancel = () => {
    setModalVisible(false);
  }
  const handleOk = () => {
    const { form } = infoFormRef.props;

    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      const interestArr: any[] = [];
      if (lookupsCollection) {
        for (let i = 0; i < lookupsCollection?.interest.length; i++) {
          if (values.interests.includes(lookupsCollection?.interest[i].name)) {
            interestArr.push(lookupsCollection?.interest[i].name);
          }
        }
      }
      const connectionArr: any[] = [];
      if (lookupsCollection) {
        for (let i = 0; i < lookupsCollection?.connectionType.length; i++) {
          if (values.connection_interests.includes(lookupsCollection?.connectionType[i].name)) {
            connectionArr.push(lookupsCollection?.connectionType[i].name);
          }
        }
      }
      form.resetFields();
      let updatedPost = {
        body: values.body,
        company: values.company,
        connection_interests: connectionArr,
        description: values.description,
        interests: interestArr,
        locationName: values.locationName,
        name: values.name,
        locationType: values.isRemote,
        url: values.url,
        postType: location.pathname === '/jobs' ? 'job opportunity' : 'news'
      };
      const postToSend = {
        ...updatedPost,
      };
      createJobPost(postToSend)

      setInfoModalVisible(false);
    });
  };
  return (
    // <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    //   <Icon type="clock-circle" style={{fontSize: '2rem', margin: '6rem 0 1rem 0'}} />
    //   <div>Coming Soon</div>
    // </div>

    <div className="list-wrapper">
      {renderRedirectToPost()}
      <PostCreateModal
        wrappedComponentRef={saveInfoFormRef}
        creatingJob={location.pathname === '/jobs'}
        onSubmit={handleOk}
        visible={modalVisible}
        onCancel={handleCancel}
        lookups={lookupsCollection}
        onOk={handleOk}
      />
      <Button onClick={() => setInfoModalVisible(true)} key="3">
        {location.pathname === '/jobs' ? 'Post Job' : 'Create Post'}
      </Button>
      <Button 
        onClick={onCSVExport.bind(null, location.pathname === '/jobs' ? 'Job_Posts': 'Posts', formattedPosts, columns)}
        style={{marginLeft: '1rem', marginRight: '1rem' }}
      >
        Export to CSV
      </Button>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: () => setRedirectToPost(record),
            // onClick: () => goToBlankStandIn(record),
          };
        }}
        dataSource={formattedPosts}
        columns={columns}
        rowKey="id"
        loading={isFetching}
      />
    </div>

  );
}

export default PostsLists;