import React, { useReducer } from 'react';
import { GET_ADMIN_USERS_RESPONSE, USER_ERROR, ADD_ADMIN_USER, GET_ADMIN_USERS_REQUEST } from '../types';
import userReducer from './userReducer';
import { userContext as UserContext } from './userContext';
import PropTypes from 'prop-types';
import { getAllAdminUsers, addNewAdminUserApiCall } from '../../utils/safeproject-api-client';
import { User } from '../../models/user';
const UserState = ( props: { children: any } ) => {
  const initialState: any = {
    adminUsers: [],
  };
  const [state, dispatch] = useReducer( userReducer, initialState );
  const getAdminUsers = async () => {
    dispatch({ type: GET_ADMIN_USERS_REQUEST });
    try {
      const adminUsers = await getAllAdminUsers();
      dispatch( {
        type: GET_ADMIN_USERS_RESPONSE,
        payload: adminUsers,
      } );
    } catch ( err ) {
      dispatch( {
        type: USER_ERROR,
      } );
    }

  }
  const addAdminUser = async (user: User) => {
    try {
      const newAdmin = await addNewAdminUserApiCall(user);
      dispatch( {
        type: ADD_ADMIN_USER,
        payload: newAdmin,
      });
    } catch (err) {
      dispatch( {
        type: USER_ERROR,
      })
    }
  }
  return <UserContext.Provider
    value={{
      isFetching: state.isFetching,
      getAdminUsers,
      adminUsers: state.adminUsers,
      addAdminUser,
    }}>
    {props.children}
  </UserContext.Provider>
}
export default UserState;

UserState.propTypes = {
  children: PropTypes.shape( {} ),
};

UserState.defaultProps = {
  children: {},
};