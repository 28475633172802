import React, { useReducer } from 'react';
import { chatContext as ChatContext } from './chatContext';
import chatReducer from './chatReducer';
import PropTypes from 'prop-types';
import { Chat } from '../../models/chat';
import { listChats, flagMessageForReview, listGroups } from '../../utils/safeproject-api-client';
import { GET_CHATS_REQUEST, GET_CHATS_RESPONSE, CHATS_ERROR, GET_MEMBER_GROUPS } from '../types';
const ChatState = (props: { children: any }) => {
  const initialState: any = {
    chats: [],
  };
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const getChats = async (type: string, id: string) => {
    dispatch({ type: GET_CHATS_REQUEST });
    try {
      const result = await listChats(type, id!);
      const events = result.items ? result.items : result;
      dispatch({
        type: GET_CHATS_RESPONSE,
        payload: events,
      });
    } catch (err) {
      dispatch({
        type: CHATS_ERROR,
      });
    }
  }

  const getMemberGroups = async (memberId: string) => {
    dispatch({ type: GET_CHATS_REQUEST });
    try {
      const result = await listGroups(memberId);
      dispatch({
        type: GET_MEMBER_GROUPS,
        payload: result,
      })
    } catch (err) {
      dispatch({
        type: CHATS_ERROR
      })
    }
  }
  const flagMessage = async (message: Chat) => {
    try {
      await flagMessageForReview(message);
    } catch (err) {
      dispatch({
        type: CHATS_ERROR
      });
    }
  }

  return <ChatContext.Provider
    value={{
      getChats,
      chats: state.chats,
      isFetching: state.isFetching,
      flagMessage,
      getMemberGroups,
      groups: state.groups,
    }}>
    {props.children}
  </ChatContext.Provider>
}

export default ChatState;

ChatState.propTypes = {
  children: PropTypes.shape({}),
};

ChatState.defaultProps = {
  children: {},
};
