/* eslint-disable no-useless-concat */
import { API } from 'aws-amplify';
import { Program } from '../models/program';
import { User } from '../models/user';
import { Post } from '../models/post';
import { Lookup, LookupType } from '../models/lookups';
import { Chat } from '../models/chat';
import { Event, PhotoFile, InitiateEventPhotoUploadResponse } from '../models/event';
import * as firebase from 'firebase/app';
import axios from 'axios'; // AxiosResponse
const API_NAME = 'SafeProjectAdminRestAPI';

async function getHeaders(
  extraHeaders?: any,
  allowsAnon: boolean = false,
): Promise<any> {
  // Set API Key and auth token headers to be passed in all API requests
  const headers: any = {
    // 'X-Api-Key': config.aws.API.apiKey,
  };
  if (!allowsAnon) {
    let token;

    const { currentUser } = firebase.auth();

    if (currentUser) {
      token = await currentUser.getIdToken();
    }

    if (token) {
      headers.Authorization = token;
    }
    return headers;
  }
  return headers;
}

export async function getUserProfile(): Promise<any> {
  return API.get(API_NAME, '/me', { headers: await getHeaders() });
}

export async function getProgramById(programId: string): Promise<any> {
  return API.get(API_NAME, `/programs/${programId}`, { headers: await getHeaders() });
}

export async function addProgram(program: Program): Promise<any> {
  return API.post(API_NAME, '/programs', {
    body: program,
    headers: await getHeaders()
  });
}

export async function getPostById(postId: string): Promise<any> {
  return API.get(API_NAME, `/posts/${postId}`, { headers: await getHeaders() });
}

export async function editProgram(program: Program): Promise<any> {
  return API.put(API_NAME, `/programs/${program.id}`, {
    body: program,
    headers: await getHeaders()
  })
}
export async function deleteSinglePost(postId: string): Promise<any> {
  return API.del(API_NAME, `/posts/${postId}`, { headers: await getHeaders() });
}

export async function deleteSingleProgram(program: Program): Promise<any> {
  return API.del(API_NAME, `/programs/${program.id}`, {
    headers: await getHeaders()
  })
}

export async function deleteSingleMember(programId: string, memberId: string): Promise<any> {
  return API.del(API_NAME, `/programs/${programId}/members/${memberId} `, {
    headers: await getHeaders()
  })
}

export async function getAllLookups(): Promise<any> {
  const res = await API.get(API_NAME, '/lookups', { headers: await getHeaders() })
  return res;
}

export async function createLookup(lookup: Lookup): Promise<any> {
  return API.post(API_NAME, '/lookups', {
    body: lookup,
    headers: await getHeaders()
  })
}

export async function removeLookup(lookupId: any, lookupType: any): Promise<any> {
  return API.del(API_NAME, `/lookups/${lookupType}-${lookupId}`, {
    headers: await getHeaders()
  })
}

export async function updateLookup(lookupType: LookupType, lookup: Lookup): Promise<any> {
  return API.put(API_NAME, `/lookups/${lookupType}-${lookup.id}`, {
    body: lookup,
    headers: await getHeaders()
  })
}

export async function getAllAdminUsers(): Promise<any> {
  const res = await API.get(API_NAME, '/users', { headers: await getHeaders() });
  const all = res;
  all.sort((a: any, b: any) => { return a.name.localeCompare(b.name) });
  return all;
}

export async function addNewAdminUserApiCall(user: User): Promise<any> {
  return API.post(API_NAME, '/users', {
    body: user,
    headers: await getHeaders()
  })
}

export async function getEventById(eventId: string, programId?: string): Promise<any> {
  const event = await API.get(API_NAME, (!!programId) ? `/programs/${programId}/events/${eventId}` : `/events/${eventId}`, { headers: await getHeaders() });
  console.log('getEventById', JSON.stringify(event, null, 2));
  return event;
}

export async function deleteSingleEvent(event: Event): Promise<any> {
  return API.del(API_NAME, `/events/${event.id}`, {
    headers: await getHeaders()
  })
}
export async function editEvent(event: Event, programId?: string): Promise<any> {
  return API.put(API_NAME, (!!programId) ? `/programs/${programId}/events/${event.id}` : `/events/${event.id}`, {
    body: event,
    headers: await getHeaders()
  })
}
export async function createNewJob(post: Post): Promise<any> {
  return API.post(API_NAME, '/posts', {
    body: post,
    headers: await getHeaders()
  });
}
export async function editPost(post: Post): Promise<any> {
  return API.put(API_NAME, `/posts/${post.id}`, {
    body: post,
    headers: await getHeaders()
  });
}

export async function addEvent(event: Event): Promise<any> {
  return API.post(API_NAME, '/events', {
    body: event,
    headers: await getHeaders()
  });
}
export async function listPrograms(searchParams?: any): Promise<any> {
  let url = searchParams ? '/programs?' : '/programs';
  for (let key in searchParams) {
    url += `${key}` + '=' + `${searchParams[key]}&`
  }
  return API.get(API_NAME, url, { headers: await getHeaders() });
}
export async function listEvents(searchParams?: any, programId?: string): Promise<any> {
  let url = '/events/?'
  if (programId) {
    const res = await API.get(API_NAME, `/programs/${programId}/events`, { headers: await getHeaders() })
    const all = res.items;
    all.sort((a: any, b: any) => { return new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime() });
    const allRes = {
      items: all,
      totalHits: all.length,
    };
    return allRes;
  } else {
    for (let key in searchParams) {
      url += `${key}` + '=' + `${searchParams[key]}&`
    }
    const res = await API.get(API_NAME, url, { headers: await getHeaders() })
    const all = res.items;
    all.sort((a: any, b: any) => { return new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime() });
    const allRes = {
      items: all,
      totalHits: all.length,
    };
    return allRes;
  }
}
export async function listMembers(searchParams?: any, programId?: string): Promise<any> {
  let url = '/members/?'
  if (programId) {
    const domain = `/programs/${programId}/members`;
    return API.get(API_NAME, domain, { headers: await getHeaders() })
  } else {
    let isSearchingLocation = false;
    let locationSearch = '';
    for (let key in searchParams) {
      if (key.toLowerCase().includes('location')) {
        isSearchingLocation = true;
        locationSearch = searchParams[key];
      } else {
        url += `${key}` + '=' + `${searchParams[key]}&`
      }
    }
    const domain = url;
    const res =  await API.get(API_NAME, domain, { headers: await getHeaders() })
    if (isSearchingLocation) {
      const items = res.items;
      const filter = items.filter((member: any) => JSON.stringify(member).toLowerCase().includes(locationSearch.toLowerCase()));
      const filteredRes = {
        items: filter,
        totalHits: filter.length,
      };
      return filteredRes;
    }
    const all = res.items;
    all.sort((a: any, b: any) => { 
      const aName = a.name && a.name.trim().length > 0 ? a.name : 'N/A';
      const bName = b.name && b.name.trim().length > 0 ? b.name : 'N/A';
      return aName.localeCompare(bName) 
    });
    const allRes = {
      items: all,
      totalHits: all.length,
    };
    return allRes;
  }
}
export async function listGroups(id: string): Promise<any> {
  return API.get(API_NAME, `/members/${id}/groups`, { headers: await getHeaders() })
}
export async function flagMessageForReview(chat: Chat): Promise<any> {
  let url = '';
  if (chat.programId && chat.eventId) {
    url = `/programs/${chat.programId}/events/${chat.eventId}/group/messages/${chat.id}/flag`
  } else if (chat.programId) {
    url = `/programs/${chat.programId}/group/messages/${chat.id}/flag`
  } else if (chat.eventId) {
    url = `/events/${chat.eventId}/group/messages/${chat.id}/flag`
  } else if (chat.groupId) {
    url = `/groups/${chat.groupId}/messages/id/flag`
  }
  return API.post(API_NAME, url, { headers: await getHeaders() });
}
export async function listChats(type: string, id: string): Promise<any> {
  let url = '';
  if (type === 'program') {
    url = `/programs/${id}/group/messages`;
  } if (type === 'event') {
    url = `/events/${id}/group/messages`;
  } if (type === 'member') {
    url = `/members/${id}/groups`
  } if (type === 'connection') {
    url = `/groups/${id}/messages?limit=50`
  }
  url += '?limit=50';
  return API.get(API_NAME, url, { headers: await getHeaders() })
}
// export async function listMembersSearch(memberName: string): Promise<any> {
//   return API.get(API_NAME, `/members?search=${memberName}`, { headers: await getHeaders() })
// }
export async function listPosts(): Promise<any> {
  const res = await API.get(API_NAME, '/posts', { headers: await getHeaders() });
  const all = res.items;
  all.sort((a: any, b: any) => { return new Date(b.createDate).getTime() - new Date(a.createDate).getTime() });
  const allRes = {
    items: all,
    totalHits: all.length,
  };
  return allRes;
}
export async function listReviews(): Promise<any> {
  const res = await API.get(API_NAME, '/reviews/all', { headers: await getHeaders() });
  const all = res.items;
  all.sort((a: any, b: any) => { return new Date(b.createDate).getTime() - new Date(a.createDate).getTime() });
  const allRes = {
    items: all,
    totalHits: all.length,
  };
  return allRes;
}

export async function getMemberById(memberId: string, programId: string): Promise<any> {
  return API.get(API_NAME, `/programs/${programId}/members/${memberId}`, { headers: await getHeaders() });
}
export async function approvedMessageReview(reviewId: string): Promise<any> {
  const body = {
    status: 'approved'
  }
  return API.patch(API_NAME, `/reviews/${reviewId}`, {
    body: body,
    headers: await getHeaders()
  })
}
export async function declineMessageReview(reviewId: string): Promise<any> {
  const body = {
    status: 'not approved'
  }
  return API.patch(API_NAME, `/reviews/${reviewId}`, {
    body: body,
    headers: await getHeaders()
  })
}

export async function acceptReview(reviewId: string): Promise<any> {
  const body = {
    status: 'approved'
  }
  return API.patch(API_NAME, `/reviews/${reviewId}`, {
    body: body,
    headers: await getHeaders()
  })
}

export async function deleteProgramPhoto(program: Program): Promise<any> {
  return API.del(API_NAME, `/programs/${program.id}/photo`, { headers: await getHeaders() })
}

export async function deleteEventPhoto(event: Event): Promise<any> {
  if (event.programId) {
    return API.del(API_NAME, `/programs/${event.programId}/events/${event.id}/photo`, { headers: await getHeaders() })
  }
  else {
    return API.del(API_NAME, `/events/${event.id}/photo`, { headers: await getHeaders() })
  }
}

export async function uploadProgramPhoto(program: Program, photoFile: PhotoFile): Promise<any> {
  const metadata = {
    title: program.name,
    description: program.name,
    contentType: 'image/png',
  };
  const initiateResult: InitiateEventPhotoUploadResponse = await API.post(
    API_NAME,
    `/programs/${program.id}/photo`,
    { body: metadata, headers: await getHeaders() },
  );
  return axios.put(initiateResult.s3PutObjectUrl, photoFile, {
    headers: {
      'Content-Type': metadata.contentType,
    },
  });
}

export async function uploadPostPhoto(
  post: Post,
  photoFile: PhotoFile,
): Promise<any> {
  const metadata = {
    title: post.name,
    description: post.name,
    contentType: 'image/png',
  };
  const initiateResult: InitiateEventPhotoUploadResponse = await API.post(
    API_NAME,
    `/posts/${post.id}/photo`,
    { body: metadata, headers: await getHeaders() },
  );
  return axios.put(initiateResult.s3PutObjectUrl, photoFile, {
    headers: {
      'Content-Type': metadata.contentType,
    },
  });
}


export async function uploadPhoto(
  event: Event,
  photoFile: PhotoFile,
): Promise<any> {
  const metadata = {
    title: event.name,
    description: event.name,
    contentType: 'image/png',
  };
  if (event.programId) {
    const initiateResult: InitiateEventPhotoUploadResponse = await API.post(
      API_NAME,
      `/programs/${event.programId}/events/${event.id}/photo`,
      { body: metadata, headers: await getHeaders() },
    );
    return axios.put(initiateResult.s3PutObjectUrl, photoFile, {
      headers: {
        'Content-Type': metadata.contentType,
      },
    });
  } else {
    const initiateResult: InitiateEventPhotoUploadResponse = await API.post(
      API_NAME,
      `/events/${event.id}/photo`,
      { body: metadata, headers: await getHeaders() },
    );
    return axios.put(initiateResult.s3PutObjectUrl, photoFile, {
      headers: {
        'Content-Type': metadata.contentType,
      },
    });
  }
}