/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect } from 'react';
import { Table, Button, Divider } from 'antd';
import AddLookupModal from './AddLookupModal';
import { LookupsContext, Lookup, LookupType } from '../../models/lookups'
import { lookupsContext as _lookupsContext } from '../../contexts/lookups/lookupsContext';
import { capitalize, onCSVExport } from '../../utils/utils';

const LookupTable = (props: any) => {
  const [addLookupVisible, setLookupVisible] = useState(false);
  const [lookupEdit, setLookupEdit] = useState({});
  const lookupsContext: LookupsContext = useContext(_lookupsContext);
  const addLookups = lookupsContext.addLookups!;
  const deleteLookup = lookupsContext.deleteLookup!;
  const editLookup = lookupsContext.editLookup!;
  const lookups = props.lookups;
  const [columns, setColumns] = useState<any>([]);
  const { isFetching } = lookupsContext;


  useEffect(() => {
    const c = columns;
    if (props.lookupType === LookupType.interest) {
      c.push({
        title: 'Icon',
        key: 'icon',
        render: (record: any) => {
          return (
            <span style={{ fontSize: '1.5rem' }}>
              {record.icon}
            </span>
          )
        }
      })
    }
    c.unshift({
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    })
    c.push(
      {
        title: 'Action',
        dataIndex: '',
        key: 'id',
        render: (record: any) => {
          return (
            <span>
              <a onClick={() => editClicked(record)} style={{ color: '#9266f6' }}>Edit</a>
              <Divider type="vertical" />
              <a onClick={() => deleteClicked(record)} style={{ color: '#ff4d4f' }}>Delete</a>
            </span >
          )
        }
      })

    setColumns(c);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteClicked = (record: any) => {
    deleteLookup(record.id, props.lookupType);
  }

  const editClicked = (record: any) => {
    setLookupVisible(true);
    setLookupEdit(record);
  }

  const [infoFormRef, setInfoFormRef] = useState({
    props: {
      form: { validateFields: (cb: Function) => { }, resetFields: () => { } },
    },
  });
  const onCancel = () => {
    setLookupEdit({})
    setLookupVisible(false);
  }

  const onOk = () => {
    setLookupEdit({})
    const { form } = infoFormRef.props;
    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      form.resetFields();
      const lookup: Lookup = {
        ...lookupEdit,
        type: props.lookupType,
        name: values.name,
        icon: values.icon,
      };

      if (lookup.id) {
        setLookupEdit({});
        editLookup(props.lookupType, lookup);
      } else {
        addLookups(lookup);
      }
      setLookupVisible(false);
    });
  }

  const saveInfoFormRef = (formRef: any) => setInfoFormRef(formRef);
  return <div className="list-wrapper">
    <Button
      onClick={() => setLookupVisible(true)}
      className="safeproject-action-button"
      type="primary"
      style={{ margin: '0 0 1rem 0' }}>
      Add {props.lookupType.charAt(0).toUpperCase() + props.lookupType.slice(1)}
    </Button>
    <Button 
      onClick={onCSVExport.bind(null, capitalize(props.lookupType), lookups, columns)}
      style={{marginLeft: '1rem', marginRight: '1rem' }}
    >
      Export to CSV
    </Button>
    <Table
      columns={columns}
      dataSource={lookups}
      rowKey="name"
      loading={isFetching}
    />
    <AddLookupModal
      wrappedComponentRef={saveInfoFormRef}
      onOk={onOk}
      onCancel={onCancel}
      visible={addLookupVisible}
      lookupType={props.lookupType}
      lookup={lookupEdit}
    />
  </div>
}

export default LookupTable;