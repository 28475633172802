import React, { useEffect, useContext, useState } from 'react';
import { memberContext as _memberContext } from '../../contexts/member/memberContext';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import { MemberContext } from '../../models/members';
import { ReviewStatus } from '../../models/reviews';
import { PageHeader, Button, Row, Col, Descriptions, Tabs, Icon, Modal } from 'antd';
import ChatGroup from '../Chat/ChatGroup';
const MemberDetail: React.FC<{}> = (props: any) => {
  const memberContext: MemberContext = useContext(_memberContext);
  const getSingleMember = memberContext.getSingleMember!;
  const { singleMember } = memberContext;
  const clearMember = memberContext.clearMember!;
  const deleteMember = memberContext.deleteMember!;
  const confirmMemberReview = memberContext.confirmMemberReview!;
  const [back, setBack] = useState(false);
  const { location } = props;
  const { search } = location;
  const queryParams = queryString.parse(search);
  const { reviewId } = queryParams;
  const { memberId, programId } = props.match.params;

  useEffect(() => {
    getSingleMember(memberId, programId);
    return () => {
      clearMember();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderBack = () => {
    if (reviewId && back) {
      return <Redirect to="/reviews" />;
    }
    if (back) {
      return <Redirect to="/members" />;
    }
    return null;
  };
  const { TabPane } = Tabs;
  const getLocalTime = (isoTime: any) => {
    let returnDate = new Date(isoTime);
    return `${returnDate.toLocaleDateString('en-US', {
      weekday: 'long',
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
    })}`
  }
  const detailArrayToString = (arrToParse: string | any[]) => {
    let returnString = '';
    for (let i = 0; i < arrToParse.length; i++) {
      if (i + 1 < arrToParse.length) {
        returnString += `${arrToParse[i]}, `;
      }
      else {
        returnString += `${arrToParse[i]}`;
      }
    }
    return returnString;
  }
  const renderMemberBody = (column = 1) => {
    console.log(JSON.stringify(singleMember, null, 2));
    return !!singleMember && (
      <Row gutter={16}>
        <Col span={10}>
          <Descriptions size="small" column={column}>
            <Descriptions.Item label="Name">
              {singleMember.name && singleMember.name.trim().length > 0 ? singleMember.name : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Pronouns">
              {singleMember.pronouns && singleMember.pronouns.trim().length > 0 ? singleMember.pronouns : 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Occupation">
              {singleMember.occupation || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="Graduation Date">
              { singleMember.graduation_term ? `${singleMember.graduation_term} ${singleMember.graduation_year}}` : 'N/A' }
            </Descriptions.Item>
            <Descriptions.Item label="Recovery Date">
              { singleMember.recovery_date ? getLocalTime(singleMember.recovery_date) : 'N/A' }
            </Descriptions.Item>
            <Descriptions.Item label="Contact Email">
              {singleMember.email}
            </Descriptions.Item>
            <Descriptions.Item label="Location">
              { singleMember.geo && singleMember.geo.city ? `${singleMember.geo.city}, ${singleMember.geo.state} ${singleMember.geo.zip}, ${singleMember.geo.country} @ ${singleMember.location.lat}, ${singleMember.location.lon}` : 'N/A' }
            </Descriptions.Item>
            {!!singleMember.connection_interests && (
              <Descriptions.Item label="Connection Interests">
                {detailArrayToString(singleMember.connection_interests)}
              </Descriptions.Item>
            )}
            {!!singleMember.interests && (
              <Descriptions.Item label="Interests">
                {detailArrayToString(singleMember.interests)}
              </Descriptions.Item>
            )}
            {!!singleMember.recovery_activities && (
              <Descriptions.Item label="Activities">
                {detailArrayToString(singleMember.recovery_activities)}
              </Descriptions.Item>
            )}
          </Descriptions>
        </Col>
        <Col span={10}>
          {memberPhoto()}
        </Col>
      </Row>

    )
  };
  const confirmMember = async () => {

    try {
      await confirmMemberReview(reviewId);

    } catch (error) {

    }

  }
  const handleDeleteMember = () => {
    deleteMember(programId, memberId);
    setTimeout(() => setBack(true), 1000);

  }
  const { confirm } = Modal
  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete this member?',
      content: null,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      className: 'delete-confirm-modal',
      onOk() {
        handleDeleteMember();
      },
      onCancel() {
      },
    });
  }
  const Content = ({ children, extra }: { children: any; extra: any }) => {
    return (
      <div className="content">
        <div className="main">{children}</div>
        <div className="extra">{extra}</div>
      </div>
    );
  };
  const renderMemberDetail = () => {
    return (
      !!singleMember &&
      <PageHeader
        style={{ backgroundColor: '#fff' }}
        onBack={() => setBack(true)}
        title={singleMember!.name}
        subTitle={
          singleMember && singleMember.status === ReviewStatus.pending && reviewId
            ? <Button onClick={confirmMember} key="4">Confirm Member</Button>
            : singleMember ? singleMember.status : ''
        }
        extra={[
          <Button type='danger' onClick={showDeleteConfirm} key="4">Delete Member</Button>,
        ]}>
        <Content extra={null}>{renderMemberBody()}</Content>
      </PageHeader>
    )
  }
  const memberPhoto = () => {
    if (singleMember?.photo) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', margin: 'auto', paddingTop: '30px' }}>
          <img
            style={{ display: 'block', maxHeight: '23vh', objectFit: 'cover', borderRadius: '10px'}}
            src={singleMember.photo.url}
            alt={singleMember.photo.title ? singleMember.photo.title : 'untitled photo fot event'}
          />
        </div>
      );
    }
  }
  return (
    <div style={{ padding: '0rem 1rem' }}>
      {renderBack()}
      {renderMemberDetail()}
      {/* {renderMemberBody()} */}
      {/* <Tabs> */}
        {/* <TabPane tab="Connections" key="1connections">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon type="clock-circle" style={{ fontSize: '2rem', margin: '6rem 0 1rem 0' }} />
            <div>Coming Soon</div>
          </div>
        </TabPane> */}
        {/* <TabPane tab="Chat Groups" key="2chat">
          <div>
            <ChatGroup member={singleMember} />
          </div>
        </TabPane> */}
        {/* <TabPane tab="Events" key="3events">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon type="clock-circle" style={{ fontSize: '2rem', margin: '6rem 0 1rem 0' }} />
            <div>Coming Soon</div>
          </div>
        </TabPane>
        <TabPane tab="Community Posts" key="4communityPosts">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon type="clock-circle" style={{ fontSize: '2rem', margin: '6rem 0 1rem 0' }} />
            <div>Coming Soon</div>
          </div>
        </TabPane>
        <TabPane tab="Profile Posts" key="5profilePosts">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon type="clock-circle" style={{ fontSize: '2rem', margin: '6rem 0 1rem 0' }} />
            <div>Coming Soon</div>
          </div>
        </TabPane> */}
      {/* </Tabs> */}

    </div>
  );
}

export default MemberDetail