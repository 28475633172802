import React, { useReducer } from 'react';
import { GET_LOOKUPS_RESPONSE, LOOKUP_ERROR, LOOKUP_CREATE, LOOKUP_DELETE, LOOKUP_UPDATE, GET_LOOKUPS_REQUEST } from '../types';
import lookupsReducer from './lookupsReducer';
import { lookupsContext as LookupsContext } from './lookupsContext';
import PropTypes from 'prop-types';
import { getAllLookups, createLookup, removeLookup, updateLookup } from '../../utils/safeproject-api-client';
import { Lookup, LookupType } from '../../models/lookups';
const LookupsState = (props: { children: any }) => {
  const initialState: any = {
    lookupsCollection: [],
  };
  const [state, dispatch] = useReducer(lookupsReducer, initialState);
  const getLookupsFromApi = async () => {
    dispatch({ type: GET_LOOKUPS_REQUEST });
    try {
      const lookups = await getAllLookups();
      dispatch({
        type: GET_LOOKUPS_RESPONSE,
        payload: lookups,
      });
    } catch (err) {
      dispatch({
        type: LOOKUP_ERROR,
      });
    }
  }
  const addLookups = async (lookUp: Lookup) => {
    try {
      const response = await createLookup(lookUp);
      dispatch({
        type: LOOKUP_CREATE,
        payload: response,
      });
    } catch (err) {
      dispatch({
        type: LOOKUP_ERROR,
      })
    }
  }

  const deleteLookup = async (lookupId: any, lookupType: any) => {
    try {
      await removeLookup(lookupId, lookupType);
      const payloadToSend = {
        lookupId: lookupId,
        lookupType: lookupType
      }
      dispatch({
        type: LOOKUP_DELETE,
        payload: payloadToSend,
      })
    } catch (err) {
      dispatch({
        type: LOOKUP_ERROR,
      })
    }
  }

  const editLookup = async (lookupType: LookupType, lookup: Lookup) => {
    try {
      await updateLookup(lookupType, lookup);
      dispatch({
        type: LOOKUP_UPDATE,
        payload: { lookupType, lookup },
      })
    } catch (err) {
      dispatch({
        type: LOOKUP_ERROR,
      })
    }
  }

  return <LookupsContext.Provider
    value={{
      isFetching: state.isFetching,
      getLookupsFromApi,
      lookupsCollection: state.lookupsCollection,
      addLookups,
      deleteLookup,
      editLookup,
    }}>
    {props.children}
  </LookupsContext.Provider>
}
export default LookupsState;

LookupsState.propTypes = {
  children: PropTypes.shape({}),
};

LookupsState.defaultProps = {
  children: {},
};