import React, { useEffect, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { PostContext } from '../../models/post';
import { LookupsContext } from '../../models/lookups';
import { postContext as _postContext } from '../../contexts/post/postContext';
import { lookupsContext as _lookupContext } from '../../contexts/lookups/lookupsContext';
import { ReviewStatus } from '../../models/reviews';
import AddPhotoModal from '../Photo/AddPhotoModal';
import PostCreateModal from './PostCreateModal';
import { Icon, PageHeader, Button, Descriptions, Col, Row, Modal } from 'antd';
import queryString from 'query-string';


const PostDetail: React.FC<{}> = (props: any) => {
  const postContext: PostContext = useContext(_postContext);
  const lookupsContext: LookupsContext = useContext(_lookupContext);
  const getSinglePost = postContext.getSinglePost!;
  const { lookupsCollection } = lookupsContext;
  const updatePost = postContext.updatePost!;
  const getLookups = lookupsContext.getLookupsFromApi!;
  const { singlePost } = postContext;
  const { location } = props;
  const { search } = location;
  const queryParams = queryString.parse(search);
  const [uploadActive, setUploadActive] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [infoFormRef, setInfoFormRef] = useState({
    props: {
      form: { validateFields: (cb: Function) => { }, resetFields: () => { } },
    },
  });
  const { reviewId } = queryParams;
  const deletePost = postContext.deletePost!;
  const confirmPostReview = postContext.confirmPostReview!;
  const [back, setBack] = useState(false);
  const { confirm } = Modal;
  useEffect(() => {
    getLookups();
    getSinglePost(props.match.params.postId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderBack = () => {
    if (back && singlePost?.postType === 'news') {
      return <Redirect to="/posts" />;
    } else if (back && singlePost?.postType === 'job opportunity') {
      return <Redirect to="/jobs" />;
    }
    return null;
  };
  const confirmPost = async () => {

    try {
      await confirmPostReview(reviewId);

    } catch (error) {

    }

  };
  const withHttp = (url: string) => !/^https?:\/\//i.test(url) ? `http://${url}` : url;
  const onCancelUpload = () => {
    setUploadActive(false);
  }
  const getLocalTime = (isoTime: any, noTime: boolean = false) => {
    let returnDate = new Date(isoTime);
    return `${returnDate.toLocaleDateString('en-US', {

      weekday: 'long',
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour: !noTime ? 'numeric' : undefined,
      minute: !noTime ? 'numeric' : undefined,
    })}`
    // return date;
  }
  const handleDeletePost = () => {
    deletePost(singlePost!.id);
    setTimeout(() => setBack(true), 1000);
  }
  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete this post?',
      content: null,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      className: 'delete-confirm-modal',
      onOk() {
        handleDeletePost();
      },
      onCancel() {
      },
    });
  }
  const detailArrayToString = (arrToParse: string | any[]) => {
    let returnString = '';
    for (let i = 0; i < arrToParse.length; i++) {
      if (i + 1 < arrToParse.length) {
        returnString += `${arrToParse[i]}, `;
      }
      else {
        returnString += `${arrToParse[i]}`;
      }

    }
    return returnString;
  }
  const handleUploadModal = () => {
    setUploadActive(true);
  }
  const postPhoto = () => {
    if (singlePost?.photo) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', margin: 'auto', paddingTop: '30px' }}>
          <img
            style={{ display: 'block', maxHeight: '23vh', objectFit: 'cover' }}
            src={singlePost.photo.url}
            alt={singlePost.photo.title ? singlePost.photo.title : 'untitled photo fot event'}
          />
          <Button style={{ marginTop: '1rem' }} className="upload-button" onClick={handleUploadModal}>
            <Icon type="upload" />
            {' '}
          Click to Change Photo
        </Button>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto', paddingTop: '30px' }} >
        <Button style={{ margin: '1rem' }} className="upload-button" onClick={handleUploadModal}>
          <Icon type="upload" />
          {' '}
          Click to Add Photo
        </Button>
      </div>
    );
  }

  const renderPostDetailBodyJob = (column = 1) => {
    return (
      <Col span={8} >
        <Descriptions size='middle' column={column}>
          {singlePost?.jobTitle && <Descriptions.Item label="Job Title">
            {singlePost?.jobTitle}
          </Descriptions.Item>}

          {singlePost?.company && <Descriptions.Item label="Company">
            {singlePost?.company}
          </Descriptions.Item>}
          {singlePost?.description && <Descriptions.Item label="Description">
            {singlePost?.description}
          </Descriptions.Item>}
          {singlePost!.locationName && <Descriptions.Item label="Location">
            {singlePost!.locationName}
          </Descriptions.Item>}
          {singlePost!.url && <Descriptions.Item label="URL">
            <a target='_blank' rel='noopener noreferrer' href={withHttp(singlePost!.url)}>{singlePost!.url}</a>
          </Descriptions.Item>}
        </Descriptions>
      </Col>
    );
  }
  const renderPostBody = (column = 1) => {
    return !!singlePost && (
      <div style={{ paddingTop: '20px' }}>
        <Row gutter={16}>
          <Col span={8} >
            <Descriptions size="small" column={column}>
              {singlePost.postType && <Descriptions.Item label="Post Type">
                {singlePost!.postType?.charAt(0).toUpperCase()}{singlePost!.postType?.slice(1)}
              </Descriptions.Item>}
              {singlePost?.createdByUser.name && <Descriptions.Item label="Created By Name">
                {singlePost?.createdByUser.name}
              </Descriptions.Item>}
              {singlePost?.createdByUser.email && <Descriptions.Item label="Created By Email">
                {singlePost?.createdByUser.email}
              </Descriptions.Item>}
              {singlePost?.createdByUser.occupation && <Descriptions.Item label="Created By Occupation">
                {singlePost?.createdByUser.occupation}
              </Descriptions.Item>}
              {singlePost?.createdByUser.graduation_date && <Descriptions.Item label="Created By Graduation Date">
                {getLocalTime(singlePost?.createdByUser.graduation_date, true)}
              </Descriptions.Item>}
              {singlePost?.createdByUser.recovery_date && <Descriptions.Item label="Created By Recovery Date">
                {getLocalTime(singlePost?.createdByUser.recovery_date, true)}
              </Descriptions.Item>}
              {singlePost?.createdByUser.interests && <Descriptions.Item label="Created By Interests">
                {detailArrayToString(singlePost?.createdByUser.interests)}
              </Descriptions.Item>}
              {singlePost?.createdByUser.recovery_activities && <Descriptions.Item label="Created By Activities">
                {detailArrayToString(singlePost?.createdByUser.recovery_activities)}
              </Descriptions.Item>}
              {singlePost?.createdByUser.role && <Descriptions.Item label="Created By Role">
                {singlePost?.createdByUser.role}
              </Descriptions.Item>}{singlePost?.createdByUser.status && <Descriptions.Item label="Created By Status">
                {singlePost?.createdByUser.status}
              </Descriptions.Item>}
            </Descriptions>{' '}
          </Col>
          {singlePost.postType === 'job opportunity' ?
            renderPostDetailBodyJob() :
            singlePost!.body ? <Col span={8}><Descriptions size='middle' column={column}><Descriptions.Item label="Post Body">
              {singlePost!.body}
            </Descriptions.Item></Descriptions></Col> : singlePost!.description ? <Col span={8}><Descriptions size='middle' column={column}><Descriptions.Item label="Description">
              {singlePost!.description}
            </Descriptions.Item></Descriptions></Col> :
                null
          }
          <Col span={8}>
            <Descriptions size="middle" column={column}>
              {!!singlePost!.createDate && <Descriptions.Item label="Created On">
                {getLocalTime(singlePost!.createDate)}
              </Descriptions.Item>}
              {!!singlePost.updateDate && <Descriptions.Item label="Updated On">
                {getLocalTime(singlePost.updateDate)}
              </Descriptions.Item>}
              {postPhoto()}
            </Descriptions>{' '}
          </Col>
          <Col span={8}>

          </Col>
        </Row>
      </div>
    )
  };
  const setInfoModalVisible = (boolVal: React.SetStateAction<boolean>) => {
    setModalVisible(boolVal);
  }
  const handleCancel = () => {
    setModalVisible(false);
  }
  const saveInfoFormRef = (formRef: any) => setInfoFormRef(formRef);
  const renderPostHeader = () => {
    return (!!singlePost &&
      <PageHeader
        style={{ backgroundColor: '#fff' }}
        onBack={() => setBack(true)}
        title={singlePost!.name}
        subTitle={
          singlePost && reviewId && singlePost.status === ReviewStatus.pending
            ? <Button onClick={confirmPost} key="4">Confirm Post</Button>
            : singlePost ? singlePost.status : ''
        }
        extra={[
          <Button onClick={() => setInfoModalVisible(true)} key="3">
            Edit Post
          </Button>,
          <Button
            type='danger'
            onClick={showDeleteConfirm}
            key="4"
          >
            Delete Post
          </Button>,
        ]}>
        <Content extra={null}>
          {renderPostBody()}
        </Content>
      </PageHeader>
    )
  }
  const Content = ({ children, extra }: { children: any; extra: any }) => {
    return (
      <div className="content">
        <div className="main">{children}</div>
        <div className="extra">{extra}</div>
      </div>
    );
  };
  const handleOk = () => {
    const { form } = infoFormRef.props;

    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      const interestArr: any[] = [];
      if (lookupsCollection) {
        for (let i = 0; i < lookupsCollection?.interest.length; i++) {
          if (values.interests.includes(lookupsCollection?.interest[i].name)) {
            interestArr.push(lookupsCollection?.interest[i].name);
          }
        }
      }
      const connectionArr: any[] = [];
      if (lookupsCollection) {
        for (let i = 0; i < lookupsCollection?.connectionType.length; i++) {
          if (values.connection_interests.includes(lookupsCollection?.connectionType[i].name)) {
            connectionArr.push(lookupsCollection?.connectionType[i].name);
          }
        }
      }
      form.resetFields();
      let updatedPost = {
        body: values.body,
        company: values.company,
        connection_interests: connectionArr,
        description: values.description,
        interests: interestArr,
        locationName: values.locationName,
        name: values.name,
        type: values.type,
        url: values.url,
        city: values.city,
        state: values.state,
        zip: values.zip,
        address: values.address,
      };
      const postToSend = {
        ...singlePost,
        ...updatedPost,
      };
      updatePost(postToSend);

      setInfoModalVisible(false);
    });
  };
  return (
    singlePost ? (
      <div style={{ padding: '0rem 1rem' }}>
        <AddPhotoModal post={singlePost} visible={uploadActive} onCancel={onCancelUpload} />
        {renderBack()}
        {renderPostHeader()}
        {/* <Tabs>
          <TabPane tab='Post Photo' key='photo'>
            <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>{postPhoto()}</div>
          </TabPane>
        </Tabs> */}
        <PostCreateModal
          wrappedComponentRef={saveInfoFormRef}
          post={singlePost}
          onSubmit={handleOk}
          visible={modalVisible}
          onCancel={handleCancel}
          lookups={lookupsCollection}
          onOk={handleOk}
        />
      </div>
    ) : (null)
  )


}

export default PostDetail