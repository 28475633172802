import {
  GET_MEMBERS_RESPONSE, GET_SINGLE_MEMBER, ACCEPT_MEMBER, CLEAR_MEMBER, GET_MEMBERS_REQUEST,
} from '../types';
import { ReviewStatus } from '../../models/reviews';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case GET_MEMBERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_MEMBERS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        members: action.payload,
      };
    case GET_SINGLE_MEMBER:
      return {
        ...state,
        singleMember: action.payload,
      }
    case ACCEPT_MEMBER:
      return {
        ...state,
        singleMember: { ...state.singleMember, status: ReviewStatus.approved }
      }
    case CLEAR_MEMBER: {
      return {
        ...state,
        singleMember: null,
      }
    }
    default:
      return state;
  }
};
