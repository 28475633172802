import React from 'react';
import PropTypes from 'prop-types';
import Login from './Login';

const Landing: React.FC<{}> = (props: any) => {
  return (
    <div>
      <Login />
    </div>
  )
};

export default Landing;

Landing.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Landing.defaultProps = {
  location: {},
};