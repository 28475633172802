export interface LookupsContext {
  getLookupsFromApi?: Function;
  lookupsCollection?: {
    interest: Lookup[],
    connectionType: Lookup[],
    recovery: Lookup[],
    occupation: Lookup[],
  };
  addLookups?: Function;
  editLookup?: Function;
  getLookups?: Function;
  deleteLookup?: Function;
  isFetching?: boolean;
}

export interface Lookup {
  id?: string,
  name: string,
  icon: string,
  type?: LookupType,
}

export interface LookupItem {
  name?: string,
  id?: string,
}

export enum LookupType {
  interest = 'interest',
  connectionType = 'connectionType',
  recovery = 'recovery',
  occupation = 'occupation',
}