import React from 'react';
import Amplify from 'aws-amplify';
import * as firebase from 'firebase/app';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import './App.css';

import AuthState from './contexts/auth/AuthState';
import PrivateRoute from './components/Auth/PrivateRoute';
import PublicRoute from './components/Auth/PublicRoute';
import Login from './components/Auth/Login';
import Landing from './components/Auth/Landing';
import ProgramsList from './components/Programs/ProgramsList';
import ProgramDetail from './components/Programs/ProgramDetail';
import ProgramState from './contexts/program/ProgramState';
import MembersList from './components/MembersList/MembersList';
import MemberDetail from './components/MembersList/MemberDetail';
import MemberState from './contexts/member/MemberState';
import LookupsList from './components/Lookups/LookupsList';
import LookupsState from './contexts/lookups/LookupsState';
import UserState from './contexts/user/UserState';
import EventsList from './components/Events/EventsList';
import EventState from './contexts/event/EventState';
import PostsLists from './components/Posts/PostsLists';
import PostDetail from './components/Posts/PostDetail';
import ReviewsState from './contexts/reviews/ReviewsState';
import ReviewsList from './components/Reviews/ReviewsList';

import Nav from './components/Nav/Nav';

import config from './config';
import AdminUserList from './components/Users/UserList';
import EventDetail from './components/Events/EventDetail';
import PostState from './contexts/post/PostState';
import ChatState from './contexts/chat/ChatState';

Amplify.configure(config.aws);

firebase.initializeApp(config.firebase);

const App: React.FC = () => (
  <div>
    <AuthState>
      <ChatState>
        <ProgramState>
          <MemberState>
            <ReviewsState>
              <LookupsState>
                <UserState>
                  <EventState>
                    <PostState>
                      <Router>
                        <Nav />
                        <Switch>
                          <PublicRoute exact path="/login" component={Login} />
                          <PublicRoute exact path="/" component={Landing} />
                          {/* <PrivateRoute exact path="/reviews" component={ReviewsList} /> */}
                          {/* <PrivateRoute exact path="/programs" component={ProgramsList} /> */}
                          {/* <PrivateRoute exact path="/programs/:programId" component={ProgramDetail} /> */}
                          <PrivateRoute exact path="/programs/:programId/members/:memberId" component={MemberDetail} />
                          <PrivateRoute exact path="/programs/:programId/events/:eventId" component={EventDetail} />
                          <PrivateRoute exact path="/members" component={MembersList} />
                          {/* <PrivateRoute exact path="/members/:memberId" component={MemberDetail} /> */}
                          <PrivateRoute exact path="/events" component={EventsList} />
                          <PrivateRoute exact path="/events/:eventId" component={EventDetail} />
                          <PrivateRoute exact path="/jobs" component={PostsLists} />
                          <PrivateRoute exact path="/posts" component={PostsLists} />
                          <PrivateRoute exact path="/posts/:postId" component={PostDetail} />
                          {/* <PrivateRoute exact path="/chat" component={ChatComponent} /> */}
                          <PrivateRoute exact path="/lookups" component={LookupsList} />
                          <PrivateRoute exact path="/admins" component={AdminUserList} />
                        </Switch>
                      </Router>
                    </PostState>
                  </EventState>
                </UserState>
              </LookupsState>
            </ReviewsState>
          </MemberState>
        </ProgramState>
      </ChatState>
    </AuthState>
  </div>
);

export default App;
