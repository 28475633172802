import { DELETE_PHOTO, GET_EVENTS_RESPONSE, GET_SINGLE_EVENT, UPDATE_EVENT, EVENT_CREATE, CLEAR_EVENT, GET_EVENTS_REQUEST, ACCEPT_EVENT } from '../types';
import { ReviewStatus } from '../../models/reviews';

export default ( state: any, action: { type: string; payload?: any } ) => {
  switch ( action.type ) {
    case GET_EVENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_EVENTS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        events: action.payload,
      };
    case GET_SINGLE_EVENT:
      return {
        ...state,
        singleEvent: action.payload,
      }
    case UPDATE_EVENT: {
      return {
        ...state,
        singleEvent: action.payload,
      }
    }
    case DELETE_PHOTO: {
      return {
        ...state,
        singleEvent: { ...state.singleEvent, photo: null}
      }
    }
    case EVENT_CREATE: {
      return {
        ...state,
        // events: [action.payload, ...state.events],
      }
    }
    case CLEAR_EVENT: {
      return {
        ...state,
        singleEvent: null,
      }
    }
    case ACCEPT_EVENT:
      return {
        ...state,
        singleEvent: { ...state.singleEvent, status: ReviewStatus.approved }
      }
    default:
      return state;
  }
};