import { GET_POSTS_REQUEST, GET_POSTS_RESPONSE, POSTS_ERROR, GET_SINGLE_POST, UPDATE_POST, ACCEPT_POST } from '../types';
import { ReviewStatus } from '../../models/reviews';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case GET_POSTS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_POSTS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        posts: action.payload,
      };
    case GET_SINGLE_POST:
      return {
        ...state,
        singlePost: action.payload,
      }
    case ACCEPT_POST: {
      return {
        ...state,
        singlePost: { ...state.singlePost, status: ReviewStatus.approved }
      }
    }
    case UPDATE_POST: {
      return {
        ...state,
        singlePost: action.payload,
      }
    }
    case POSTS_ERROR:
      return {}
    default:
      return state;
  }
};