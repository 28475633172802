import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import { AuthContext } from "../../models/auth";
import { authContext as _authContext } from "../../contexts/auth/authContext";
import { Redirect } from "react-router-dom";
// import OAuthButton from './OAuthButton';

const Login: React.FC<{}> = (props: any) => {
  const authContext: AuthContext = useContext(_authContext);
  const { isAuthenticated } = authContext;
  const getMe = authContext.getMe!;
  // const signOut = authContext.signOut!;
  const setAuthLoading = authContext.setAuthLoading!;

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {
        getMe();
        return false;
      },
    },
    // We will display Google and Facebook as auth providers.
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
  };

  // TODO this is checking for params when redirected to from the login page
  // to know if we need to wait for Hub.listen() below to finish signing in
  // there's probably a better way of seeing if it's loading
  const { location } = props;
  const { search } = location;
  const queryParams = queryString.parse(search);
  const { code, state } = queryParams;
  if (code || state) {
    setAuthLoading(true);
  }
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  return (
    <>
      {
        <div className="login-wrapper">
          <div className="login-banner"></div>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      }
      {isAuthenticated ? <Redirect to="/members" /> : null}
    </>
  );
};

export default Login;

Login.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Login.defaultProps = {
  location: {},
};
