

import objectPath from "object-path";
import { saveAs } from "file-saver";

const detailArrayToString = (arrToParse: string | any[]) => {
  let returnString = '';
  for (let i = 0; i < arrToParse.length; i++) {
    if (i + 1 < arrToParse.length) {
      returnString += `${arrToParse[i]} > `;
    }
    else {
      returnString += `${arrToParse[i]}`;
    }
  }
  return returnString;
}

export const onCSVExport = (title: any, data: any, columns: any) => {
    if (!data || data.length === 0) return;
    let csv = "";
    columns.forEach(({ title, render } : any, index: any) => {
      if (render) return;
      if (index !== 0) csv += ",";
      csv += `${title.replace(/\"/g, '""')}`;
    });

    csv += "\n";

    data.forEach((record: any) => {
      columns.forEach(({ dataIndex, render } : any, index: any) => {
        if (render) return;

        if (index !== 0) csv += ",";
        
        csv += `${objectPath.get(record, dataIndex as objectPath.Path)}`;
      });
      csv += "\n";
    });

    saveAs(new Blob([csv]), `${title}.csv`);
};

export const onMemberCSVExport = (title: any, data: any, columns: any) => {
    if (!data || data.length === 0) return;
    let csv = "";
    columns.forEach(({ title, render } : any, index: any) => {
      if (index !== 0) csv += ",";
      csv += `${title.replace(/\"/g, '""')}`;
    });

    csv += "\n";

    data.forEach((record: any) => {
      const arrayStringIntrests = record && record.interests && record.interests.length > 0 ? detailArrayToString(record.interests) : 'N/A';
      const arrayStringConnectionIntrests = record && record.connection_interests && record.connection_interests.length > 0 ? detailArrayToString(record.connection_interests) : 'N/A';
      const arrayStringRecoveryIntrests = record && record.recovery_activities && record.recovery_activities.length > 0 ? detailArrayToString(record.recovery_activities) : 'N/A';
      columns.forEach(({ dataIndex, render } : any, index: any) => {
        if (index !== 0) csv += ",";
        let newRecord = record;
        if (dataIndex === 'interests' || dataIndex === 'connection_interests' || dataIndex === 'recovery_activities') {
          newRecord.interests = arrayStringIntrests;
          newRecord.connection_interests = arrayStringConnectionIntrests;
          newRecord.recovery_activities = arrayStringRecoveryIntrests;
        }
        csv += `${objectPath.get(newRecord, dataIndex as objectPath.Path)}`;
      });
      csv += "\n";
    });

    saveAs(new Blob([csv]), `${title}.csv`);
};

export const capitalize = (str: any, lower = false) =>
(lower ? str.toLowerCase() : str).replace(
  /(?:^|\s|["'([{])+\S/g,
  (match: any) => match.toUpperCase()
);