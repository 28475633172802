/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Form, Modal, Input, Row, Col } from 'antd';


interface Props {
  visible: any;
  onCancel: any;
  onOk: any;
  onSubmit: any;
  form: any;
  programName?: any;
}

class UserCreateModal extends Component<Props> {
  render () {
    const {
      visible,
      onCancel,
      onOk,
      form,
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Modal
        visible={visible}
        title={'Add an Admin'}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Form>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="First Name">
                {getFieldDecorator( 'firstName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a First Name',
                    },
                  ],
                } )( <Input /> )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name">
                {getFieldDecorator( 'lastName', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a Last Name',
                    },
                  ],
                } )( <Input /> )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Email">
            {getFieldDecorator( 'email', {
              rules: [
                {
                  required: true,
                  message: 'Please input an Email',
                },
              ],
            } )( <Input /> )}
          </Form.Item>
        </Form>
      </Modal> )
  }
}

// TODO fix typescript complaining
const userCreateForm: any = Form.create( { name: 'program_create_form' } )(
  UserCreateModal,
);

export default userCreateForm;
