import React, { useContext, useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { UserContext } from '../../models/user';
import UserCreateModal from './UserCreateModal';
import { userContext as _userContext } from '../../contexts/user/userContext';
import { onCSVExport } from '../../utils/utils';
const AdminUserList: React.FC<{}> = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const userContext: UserContext = useContext(_userContext);
  const addAdminUser = userContext.addAdminUser!;
  const getAdminUsers = userContext.getAdminUsers!;
  const { adminUsers, isFetching } = userContext;
  const [infoFormRef, setInfoFormRef] = useState({
    props: {
      form: { validateFields: (cb: Function) => { }, resetFields: () => { } },
    },

  });
  const generator = require('generate-password');
  useEffect(() => {
    getAdminUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text:any) => {
        const input = text.toLowerCase()
        const name = (input.includes('undefined ')) ? input.replace('undefined ', '') : input.includes(' undefined') ? input.replace(' undefined', '') : input;
        const capitalizedName = name.replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) => letter.toUpperCase());
        return <a>{capitalizedName}</a>
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },

  ];
  const saveInfoFormRef = (formRef: any) => setInfoFormRef(formRef);
  const handleOk = () => {
    const { form } = infoFormRef.props;

    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      form.resetFields();
      const password = generator.generate({
        length: 15,
        numbers: true,
        strict: true,
      })
      const adminUser = {
        name: values.name,
        lastName: values.lastName,
        isSystemAdministrator: true,
        email: values.email,
        password: password,
      };
      addAdminUser(adminUser);
      setModalVisible(false);
    });
  };
  const handleCancel = () => {
    setModalVisible(false);
  }

  return (
    <div className="list-wrapper">
      <Button className="safeproject-action-button" type="primary" onClick={() => setModalVisible(true)} key="3" style={{ marginBottom: '1rem' }}>
        Add Admin
      </Button>
      <Button 
        onClick={onCSVExport.bind(null, 'Admins', adminUsers, columns)}
        style={{marginLeft: '1rem', marginRight: '1rem' }}
      >
        Export to CSV
      </Button>
      <Table
        onRow={(record, rowIndex) => {
          return {
            // no admin details page for now
            // onClick: () => setRedirect( record.id ),
          };
        }}
        dataSource={adminUsers}
        columns={columns}
        rowKey="id"
        loading={isFetching}
      />
      <UserCreateModal
        wrappedComponentRef={saveInfoFormRef}
        isSystemAdministrator={true}
        onSubmit={handleOk}
        visible={modalVisible}
        onCancel={handleCancel}
        onOk={handleOk} />
    </div>
  )
}

export default AdminUserList;

