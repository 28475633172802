import React, { useReducer } from 'react';
import { programContext as ProgramContext } from './programContext';
import programReducer from './programReducer';
import PropTypes from 'prop-types';
import { PhotoFile } from '../../models/event';
// import * as myData from '../../utils/programs.json';
import { Program } from '../../models/program';
import { uploadProgramPhoto, deleteProgramPhoto, listPrograms, getProgramById, addProgram, editProgram, deleteSingleProgram } from '../../utils/safeproject-api-client';
import { PHOTO_ERROR, DELETE_PROGRAM_PHOTO, GET_PROGRAMS_RESPONSE, PROGRAM_ERROR, GET_SINGLE_PROGRAM, PROGRAM_CREATE, UPDATE_PROGRAM, CLEAR_PROGRAM, GET_PROGRAMS_REQUEST } from '../types';
const ProgramState = ( props: { children: any } ) => {
  //   const dataList = myData;
  const initialState: any = {
    programs: [],
    singleProgram: null,
  };
  const [state, dispatch] = useReducer( programReducer, initialState );
  const getPrograms = async ( program?: Program ) => {
    dispatch( { type: GET_PROGRAMS_REQUEST } );
    try {
      const result = await listPrograms( program );
      const programs = result.items ? result.items : result;
      dispatch( {
        type: GET_PROGRAMS_RESPONSE,
        payload: programs,
      } )
    } catch ( err ) {
      dispatch( {
        type: PROGRAM_ERROR,
      } );
    }
  }
  const getSingleProgram = async ( programId: string ) => {
    try {
      const program = await getProgramById( programId );
      dispatch( {
        type: GET_SINGLE_PROGRAM,
        payload: program,
      } )
    } catch ( err ) {
      dispatch( {
        type: PROGRAM_ERROR,
      } )
    }
  }
  const programCreate = async ( program: Program ) => {
    try {
      const response = await addProgram( program );
      dispatch( {
        type: PROGRAM_CREATE,
        payload: response,
      } );
    } catch ( err ) {
      dispatch( {
        type: PROGRAM_ERROR,
      } );
    }
  }
  const updateProgram = async ( program: Program ) => {
    try {
      await editProgram( program );
      dispatch( {
        type: UPDATE_PROGRAM,
        payload: program,
      } )
    } catch ( err ) {
      dispatch( {
        type: PROGRAM_ERROR,
      } );
    }
  }
  const deleteProgram = async ( program: Program ) => {
    try {
      await deleteSingleProgram( program );

    } catch ( err ) {
      dispatch( {
        type: PROGRAM_ERROR,
      } );
    }
  }

  const clearProgram = () => {
    dispatch( {
      type: CLEAR_PROGRAM,
    } );
  };
  const deletePhoto = async (program: Program) => {
    try {
      await deleteProgramPhoto(program);
      dispatch({
        type: DELETE_PROGRAM_PHOTO,
      })
    }
      catch ( err ) {
        dispatch( {
          type: PROGRAM_ERROR,
        } );
    }
  }
  const addProgramPhoto = async (program: Program, fileState: PhotoFile) => {
    try {
      await uploadProgramPhoto(
        program,
        fileState,
      );
    } catch (err) {
      dispatch({
        type: PHOTO_ERROR,
      });
    }
  }

  return <ProgramContext.Provider
    value={{
      getPrograms,
      isFetching: state.isFetching,
      programs: state.programs,
      singleProgram: state.singleProgram,
      getSingleProgram,
      programCreate,
      updateProgram,
      deleteProgram,
      clearProgram,
      deletePhoto,
      addProgramPhoto,
    }}>
    {props.children}
  </ProgramContext.Provider>
}

export default ProgramState;

ProgramState.propTypes = {
  children: PropTypes.shape( {} ),
};

ProgramState.defaultProps = {
  children: {},
};