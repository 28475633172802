import React, { useEffect, useContext } from 'react';
import { Tabs } from 'antd';
import { lookupsContext as _lookupsContext } from '../../contexts/lookups/lookupsContext';
import { LookupsContext } from '../../models/lookups';
import LookupTable from './LookupTable';

const LookupsList = (props: any) => {
  const lookupsContext: LookupsContext = useContext(_lookupsContext);
  const getLookupsFromApi = lookupsContext.getLookupsFromApi!;
  const { lookupsCollection } = lookupsContext;
  const { TabPane } = Tabs;
  useEffect(() => {
    getLookupsFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const interests = lookupsCollection?.interest || [];
  const sortedInterests = interests.sort((a: any, b: any) => { return a.name.localeCompare(b.name) });
  const connectionType = lookupsCollection?.connectionType || [];
  const sortedConnectionTypes = connectionType.sort((a: any, b: any) => { return a.name.localeCompare(b.name) });
  const recovery = lookupsCollection?.recovery || [];
  const sortedRecoveries = recovery.sort((a: any, b: any) => { return a.name.localeCompare(b.name) });
  const occupation = lookupsCollection?.occupation || [];
  const sortedOccupations = occupation.sort((a: any, b: any) => { return a.name.localeCompare(b.name) });

  return (!!lookupsCollection && <div style={{ margin: '0 1rem' }}>

    <Tabs defaultActiveKey="1" onChange={() => { }} style={{ marginBottom: 0 }}>
      <TabPane tab="Interests" key="1">
        <LookupTable lookupType='interest' lookups={sortedInterests} />
      </TabPane>
      <TabPane tab="Connection Types" key="2">
        <LookupTable lookupType='connectionType' lookups={sortedConnectionTypes} />
      </TabPane>
      <TabPane tab="Recovery Programs" key="3">
        <LookupTable lookupType='recovery' lookups={sortedRecoveries} />
      </TabPane>
      <TabPane tab="Occupations" key="4">
        <LookupTable lookupType='occupation' lookups={sortedOccupations} />
      </TabPane>
    </Tabs>



  </div>);
}
export default LookupsList;