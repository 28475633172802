import React, { Component } from 'react';
import { Form, Modal, Input } from 'antd';
import { LookupType, Lookup } from '../../models/lookups';

interface Props {
  visible: boolean;
  onCancel: any;
  onOk: any;
  form: any;
  lookupType?: LookupType;
  lookup?: Lookup;
}
class AddLookupModal extends Component<Props> {
  render() {
    const {
      visible,
      onCancel,
      onOk,
      lookupType,
      form,
      lookup,
    } = this.props;
    const { getFieldDecorator } = form;
    return <Modal
      visible={visible}
      title={lookupType ? `Add a new ${lookupType}` : 'Forbidfden'}
      onOk={onOk}
      // onSubmit={onOk}
      onCancel={onCancel}
    >
      <Form>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: lookup?.name,
            rules: [
              {
                required: true,
                message: 'Please input a Name',
              },
            ],
          })(<Input />)}
        </Form.Item>

        {lookupType === LookupType.interest ?
          (<Form.Item label="Icon">
            {getFieldDecorator('icon', {
              initialValue: lookup?.icon,
              rules: [
                {
                  required: true,
                  message: 'Please input an icon',
                },
              ],
            })(<Input />)}
          </Form.Item>) : (null)
        }
      </Form>
    </Modal>
  }
}
const lookupCreateForm: any = Form.create({ name: 'program_create_form' })(
  AddLookupModal,
);

export default lookupCreateForm;