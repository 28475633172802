import { GET_LOOKUPS_RESPONSE, LOOKUP_CREATE, LOOKUP_DELETE, LOOKUP_UPDATE, GET_LOOKUPS_REQUEST } from '../types';
import { Lookup } from '../../models/lookups';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case GET_LOOKUPS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_LOOKUPS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        lookupsCollection: action.payload,
      };
    case LOOKUP_CREATE: {
      state.lookupsCollection[action.payload.type].push(action.payload)
      return {
        ...state,
      }
    }
    case LOOKUP_DELETE: {
      const newLookupsByType = state.lookupsCollection[action.payload.lookupType].filter((lookup: any) => {
        return (lookup.id !== action.payload.lookupId);
      });
      const updatedLookups = state.lookupsCollection;
      updatedLookups[action.payload.lookupType] = newLookupsByType
      return {
        ...state,
        lookupsCollection: updatedLookups,
      }
    }
    case LOOKUP_UPDATE: {
      const index = state.lookupsCollection[action.payload.lookupType].findIndex((l: Lookup) => l.id === action.payload.lookup.id)
      const updatedLookups = state.lookupsCollection;
      updatedLookups[action.payload.lookupType][index] = action.payload.lookup;
      return {
        ...state,
        lookupsCollection: updatedLookups,
      }
    }
    default:
      return state;
  }
};
