import React, { Component } from 'react';
import { Form, Modal, Input, Row, Col, DatePicker, Radio, TimePicker } from 'antd';
import moment from 'moment';
import 'moment-timezone';
const Select: any = require('antd').Select;

interface Props {
  visible: any;
  onCancel: any;
  onOk: any;
  onSubmit: any;
  form: any;
  event?: any;
  lookups?: any;
}

class EventCreateModal extends Component<Props> {

  state = {
    isRemote: 'physical'
  }
  render() {
    const {
      visible,
      onCancel,
      onOk,
      form,
      event,
      lookups,
    } = this.props;

    const { getFieldDecorator } = form;
    const { RangePicker } = DatePicker;
    const date = moment();
    const dateFormat = 'YYYY/MM/DD';
    const rangeConfig = {
      initialValue: event ? [moment(event?.startDateTime, dateFormat), moment(event?.endDateTime, dateFormat)] :
        [moment(date, dateFormat), moment(date, dateFormat)],
      rules: [{ type: 'array' }],
    };

    const { Option } = Select;
    const defaultTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const getLocalTime = (isoTime: any) => {
      const returnDate = new Date(isoTime);
      return returnDate.getHours().toString() + ':' + returnDate.getMinutes().toString();
    }
    const tzOptions = [
      {
        value: 'America/Puerto_Rico',
        name: 'Puerto Rico (Atlantic)'
      },
      {
        value: 'America/New_York',
        name: 'New York (Eastern)'
      },
      {
        value: 'America/Chicago',
        name: 'Chicago (Central)'
      },
      {
        value: 'America/Denver',
        name: 'Denver (Mountain)'
      },
      {
        value: 'America/Phoenix',
        name: 'Phoenix (MST)'
      },
      {
        value: 'America/Los_Angeles',
        name: 'Los Angeles (Pacific)'
      },
      {
        value: 'America/Anchorage',
        name: 'Anchorage (Alaska)'
      },
      {
        value: 'Pacific/Honolulu',
        name: 'Honolulu (Hawaii)'
      }
    ]
    const setStartingInterests = () => {
      let selectedInterests = [];
      if (event.interests) {
        for (let i = 0; i < event.interests.length; i++) {
          selectedInterests.push(event.interests[i]);
        }
      }
      return selectedInterests;
    }
    const changingRemote = (e: any) => {
      this.setState({
        isRemote: e.target.value
      })
    }
    const setStartingConnections = () => {
      let selectedConnections = [];
      if (event.connection_interests) {
        for (let i = 0; i < event.connection_interests.length; i++) {
          selectedConnections.push(event.connection_interests[i]);
        }
      }
      return selectedConnections;
    }
    const selectMain = () => {
      const interest = lookups.interest;
      const children = [];


      for (let i = 0; i < interest.length; i++) {
        children.push(<Option key={interest[i].name}>{interest[i].name}</Option>);
      }

      return (
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
        >
          {children}
        </Select>);

    }
    const selectMainConnections = () => {
      const connectionType = lookups.connectionType;
      const children = [];
      for (let i = 0; i < connectionType.length; i++) {
        children.push(<Option key={connectionType[i].name}>{connectionType[i].name}</Option>);
      }
      return (
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
        >
          {children}
        </Select>);

    }
    return (
      <Modal
        visible={visible}
        title={event && event.id ? `Edit ${event.name}` : 'Add a New Event'}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Form>
          <Form.Item label="Type">
            {getFieldDecorator('type', {
              initialValue: event?.type,
            })(
              <Radio.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={12}><Radio value='local'>Local</Radio></Col>
                  <Col span={12}><Radio value='national'>National</Radio></Col>
                </Row>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="Event Title">
            {getFieldDecorator('name', {
              initialValue: event?.name,
              rules: [
                {
                  required: true,
                  message: 'Please input a Name',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Col span={24}>
            <Form.Item label="Event Date and Time">
              {getFieldDecorator('startStopDate', rangeConfig)(
                <RangePicker
                  className="eventRange"
                  showTime={false}
                  format={'MMMM Do YYYY'}
                />,
              )}
            </Form.Item>
          </Col>
          <Row gutter={[8, 16]}>
            <Col span={7}>
              <Form.Item label="Start Time">
                {getFieldDecorator('startTime', {
                  initialValue: event ? moment(getLocalTime(event.startDateTime), 'HH:mm:ss') : moment('00:00:00', 'HH:mm:ss')
                })(
                  <TimePicker
                    className="eventTime"
                    use12Hours
                    minuteStep={15}
                    format="h:mm a"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="End Time">
                {getFieldDecorator('endTime', {
                  initialValue: event ? moment(getLocalTime(event.endDateTime), 'HH:mm:ss') : moment('00:00:00', 'HH:mm:ss')
                })(
                  <TimePicker
                    className="eventTime"
                    use12Hours
                    minuteStep={15}
                    format="h:mm a"
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Time Zone">
                {getFieldDecorator('timezone', {
                  initialValue: defaultTZ,
                })
                  (<Select>
                    {tzOptions.map(tz => (
                      <Option value={tz.value} key={tz.value}>{tz.name}</Option>
                    ))}
                  </Select>
                  )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Is Remote Event?">
            {getFieldDecorator('isRemote', { onChange: changingRemote, initialValue: 'physical' })(
              <Radio.Group style={{ width: '100%' }}>
                <Row>
                  <Col span={12}><Radio value="virtual">Yes</Radio></Col>
                  <Col span={12}><Radio value="physical">No</Radio></Col>
                </Row>
              </Radio.Group>
            )}
          </Form.Item>
          {this.state.isRemote === 'physical' && <Form.Item label="Location Name">
            {getFieldDecorator('locationName', {
              initialValue: event?.locationName,
              rules: [
                {
                  required: true,
                  message: 'Please input a Name',
                },
              ],
            })(<Input />)}
          </Form.Item>}
          {this.state.isRemote === 'physical' && <Form.Item label="Street Address">
            {getFieldDecorator('address', {
              initialValue: event?.address,
            })(<Input />)}
          </Form.Item>}
          {this.state.isRemote === 'physical' && <Row gutter={8}>
            <Col span={8}>
              <Form.Item label="City">
                {getFieldDecorator('city', {
                  initialValue: event?.city,
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="State">
                {getFieldDecorator('state', {
                  initialValue: event?.state,
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Zip Code">
                {getFieldDecorator('zip', {
                  initialValue: event?.zip,
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>}
          {!!lookups && lookups.interest && <Form.Item label="Interests">
            {getFieldDecorator('interests', {
              initialValue: event ? setStartingInterests() : [],
            })(
              selectMain()
            )}
          </Form.Item>
          }

          {!!lookups && lookups.connectionType && <Form.Item label="Connections">
            {getFieldDecorator('connectionTypes', {
              initialValue: event ? setStartingConnections() : [],
            })(
              selectMainConnections()
            )}
          </Form.Item>

          }
        </Form>

      </Modal>)
  }

}

const eventCreateForm: any = Form.create({ name: 'event_create_form' })(
  EventCreateModal,
);

export default eventCreateForm;