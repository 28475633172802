import React, { useEffect, useContext, useState } from 'react';
import { Table, Input, Button } from 'antd';
import { MemberContext, Member } from '../../models/members';
import { Redirect } from 'react-router-dom';
import { memberContext as _memberContext } from '../../contexts/member/memberContext';
import { Program } from '../../models/program';
import { OTHER_PROGRAM_ID } from '../../constants';
import { onMemberCSVExport } from '../../utils/utils';

const MemberList = ({ program, searchKey, searchValue }: { program?: Program, searchKey?: string, searchValue?: string }) => {
  const memberContext: MemberContext = useContext( _memberContext );
  const [redirect, setRedirect] = useState({
    memberId: '',
    programId: '',
  });
  const [programId, setProgramId] = useState(OTHER_PROGRAM_ID);
  const [searchNameValue, setSearchNameValue] = useState();
  const [searchProgramValue, setSearchProgramValue] = useState();
  const [searchLocationValue, setSearchLocationValue] = useState();
  const getMembers = memberContext.getMembers!;
  const { members, isFetching } = memberContext;
  const { Search } = Input;

  useEffect(() => {
    if (program && programId !== program.id) {
      setProgramId(program.id);
      // TODO replace with api call to /programs/{programId}/members when it's created
      getMembers({ searchKey: 'programId', searchValue: program.id }, program?.id)
    } else {
      getMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program]);

  const detailArrayToString = (arrToParse: string | any[]) => {
    let returnString = '';
    for (let i = 0; i < arrToParse.length; i++) {
      if (i + 1 < arrToParse.length) {
        returnString += `${arrToParse[i]}, `;
      }
      else {
        returnString += `${arrToParse[i]}`;
      }
    }
    return returnString;
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // render: (text: string, record: Member) => record?.name?.trim().length > 0 ? `${record.name}` : 'N/A',
    },
    {
      title: 'City',
      dataIndex: 'geo.city',
      key: 'city',
      render: (text: string, record: Member) => record?.geo?.city?.trim().length > 0 ? `${record.geo.city}` : 'N/A',
    },
    {
      title: 'State',
      dataIndex: 'geo.state',
      key: 'state',
      render: (text: string, record: Member) => record?.geo?.state?.trim().length > 0 ? `${record.geo.state}` : 'N/A',
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      render: (text: string, record: Member) => record?.email?.trim().length > 0 ? `${record.email}` : 'N/A',
    },
    {
      title: 'Pronouns',
      dataIndex: 'pronouns',
      key: 'pronouns',
      render: (text: string, record: Member) => record && record.pronouns && record.pronouns.length > 0 ?  `${record.pronouns}` : 'N/A',
    },
    {
      title: 'Community Interests',
      dataIndex: 'connection_interests',
      key: 'connection_interests',
      render: (text: string, record: Member) => record?.connection_interests?.length > 0 ? `${detailArrayToString(record.connection_interests)}` : 'N/A',
    },
    {
      title: 'Interests',
      dataIndex: 'interests',
      key: 'interests',
      render: (text: string, record: Member) => record?.interests?.length > 0 ? `${detailArrayToString(record.interests)}` : 'N/A',
    },
    {
      title: 'Recovery Activities',
      dataIndex: 'recovery_activities',
      key: 'recovery_activities',
      render: (text: string, record: Member) => record?.recovery_activities?.length > 0 ? `${detailArrayToString(record.recovery_activities)}` : 'N/A',
    },
  ];
  const renderRedirect = () => {
    if ( redirect && redirect.programId && redirect.memberId ) {
      return <Redirect to={`/programs/${redirect.programId}/members/${redirect.memberId}`} />;
    }
    return null;
  };
  const searchMember = (paramValue: string, value: string ) => {
    let searchParams = {
      [paramValue]: value,
    };
    getMembers(
        searchParams
      );
  }
  const memberNameSearchInput = (e: { target: { value: any; }; }) => {
    setSearchNameValue(e.target.value);
  }
  const memberProgramSearchInput = (e: { target: { value: any; }; }) => {
    setSearchProgramValue(e.target.value);
  }
  const memberLocationSearchInput = (e: { target: { value: any; }; }) => {
    setSearchLocationValue(e.target.value);
  }
  const searchAllValues = () => {
    let searchParams: {[key: string]: string} = {};
    if(!!searchNameValue) {
      searchParams['search'] = searchNameValue!;
    }
    if(!!searchProgramValue) {
      searchParams['programName'] = searchProgramValue!;
    }
    if(!!searchLocationValue) {
      searchParams['location'] = searchLocationValue!;
    }
    getMembers(
      searchParams
    );
  }
  // const searchMemberInterest = (interest: string) => {
  //   let searchParams = {
  //     interests: interest,
  //   };
  //   getMembers(
  //       searchParams
  //     );
  // }

  return (
    <div className="list-wrapper">
      {renderRedirect()}
      <Search
        placeholder="Member's Name"
        allowClear={true}
        enterButton="Search"
        onChange={memberNameSearchInput}
        onSearch={(value: string) => searchMember('search', value)}
        style={{ width: 250, marginBottom: '1rem', marginRight: '1rem' }}
      />
      {/* <Search
        placeholder="Member's Program"
        allowClear={true}
        enterButton="Search"
        onChange={memberProgramSearchInput}
        onSearch={(value: string) => searchMember('programName', value)}
        style={{ width: 250, marginBottom: '1rem', marginRight: '1rem' }}
      /> */}
      <Search
        placeholder="Member's Location"
        allowClear={true}
        enterButton="Search"
        onChange={memberLocationSearchInput}
        onSearch={(value: string) => searchMember('location', value)}
        style={{ width: 250, marginBottom: '1rem', marginRight: '1rem' }}
      />
      <Button 
        onClick={searchAllValues}
      >
        Search All
      </Button>
      <Button 
        onClick={onMemberCSVExport.bind(null, 'Members', members, columns)}
        style={{marginLeft: '1rem', marginRight: '1rem' }}
      >
        Export to CSV
      </Button>
      {/* <Search
        placeholder="Search Members by Interest"
        allowClear={true}
        onSearch={(value: string) => searchMemberInterest(value)}
        style={{ width: 250, marginBottom: '1rem', marginLeft: '1rem' }}
      /> */}
      <Table
      onRow={( record, rowIndex ) => {
        return {
          onClick: () => {
            setRedirect( {
              memberId: record.id,
              programId: programId,
            } );
            renderRedirect();
          }
        };
      }}
      dataSource={members}
      columns={columns}
      rowKey="id"
      loading={isFetching}
    />
    </div>
  );
}

export default MemberList;