import React, { useReducer } from 'react';
import { reviewsContext as ReviewsContext } from './reviewsContext';
import memberReducer from './reviewsReducer';
import PropTypes from 'prop-types';
// import * as myData from '../../utils/programs.json';
import { listReviews, declineMessageReview, approvedMessageReview } from '../../utils/safeproject-api-client';
import { GET_REVIEWS_RESPONSE, REVIEWS_ERROR, GET_REVIEWS_REQUEST } from '../types';
const MemberState = (props: { children: any }) => {
  const initialState: any = {
    reviews: [],
  };
  const [state, dispatch] = useReducer(memberReducer, initialState);
  const getReviews = async () => {
    dispatch({ type: GET_REVIEWS_REQUEST });
    try {
      const reviews = await listReviews();
      dispatch({
        type: GET_REVIEWS_RESPONSE,
        payload: reviews,
      });
    } catch (err) {
      dispatch({
        type: REVIEWS_ERROR,
      });
    }
  }
  const removeFlaggedMessage = async (message: any) => {
    try {
      declineMessageReview(message.id)
    } catch (err) {
      dispatch({
        type: REVIEWS_ERROR,
      });
    }
  }
  const approveFlaggedMessage = async (message: any) => {
    try {
      approvedMessageReview(message.id)
    } catch (err) {
      dispatch({
        type: REVIEWS_ERROR,
      });
    }
  }
  return <ReviewsContext.Provider
    value={{
      isFetching: state.isFetching,
      reviews: state.reviews,
      getReviews,
      removeFlaggedMessage,
      approveFlaggedMessage,
    }}>
    {props.children}
  </ReviewsContext.Provider>
}

export default MemberState;

MemberState.propTypes = {
  children: PropTypes.shape({}),
};

MemberState.defaultProps = {
  children: {},
};