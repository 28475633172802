import {
  GET_REVIEWS_RESPONSE, GET_REVIEWS_REQUEST,
} from '../types';

export default ( state: any, action: { type: string; payload?: any } ) => {
  switch ( action.type ) {
    case GET_REVIEWS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_REVIEWS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        reviews: action.payload,
      };
    
    default:
      return state;
  }
};
