import React, { useReducer } from 'react';
import { postContext as PostContext } from './postContext';
import postReducer from './postReducer';
import PropTypes from 'prop-types';
import { Post } from '../../models/post';
import { PhotoFile } from '../../models/event';
import {
  listPosts, getPostById, acceptReview,
  deleteSinglePost, uploadPostPhoto, editPost,
  createNewJob
} from '../../utils/safeproject-api-client';
import { GET_POSTS_REQUEST, GET_POSTS_RESPONSE, POSTS_ERROR, GET_SINGLE_POST, ACCEPT_POST, PHOTO_ERROR, UPDATE_POST } from '../types';
const PostState = (props: { children: any }) => {
  const initialState: any = {
    posts: [],
    singlePost: null,
  };
  const [state, dispatch] = useReducer(postReducer, initialState);

  const getPosts = async () => {
    dispatch({ type: GET_POSTS_REQUEST });
    try {
      const result = await listPosts();
      const posts = result.items ? result.items : result;
      dispatch({
        type: GET_POSTS_RESPONSE,
        payload: posts,
      });
    } catch (err) {
      dispatch({
        type: POSTS_ERROR,
      });
    }
  }
  const getSinglePost = async (postId: string) => {
    try {
      const postReturn = await getPostById(postId);
      dispatch({
        type: GET_SINGLE_POST,
        payload: postReturn,
      })
    } catch (err) {
      dispatch({
        type: POSTS_ERROR,
      })
    }
  }

  const confirmPostReview = async (reviewId: string) => {
    return new Promise(async (resolve, reject) => {
      const confirm = {
        status: 'approved'
      }
      try {
        await acceptReview(reviewId)
        dispatch({
          type: ACCEPT_POST,
          payload: confirm,
        })
        resolve()
      } catch (err) {
        dispatch({
          type: POSTS_ERROR,
        })
        reject()
      }
    });
  }

  const createJobPost = async (post: Post) => {
    try {
      await createNewJob(post);
    } catch (err) {
      dispatch({
        type: POSTS_ERROR,
      })
    }
    await getPosts();
  }
  const updatePost = async (post: Post) => {

    try {
      await editPost(post);
      dispatch({
        type: UPDATE_POST,
        payload: post,
      })
    } catch (err) {
      dispatch({
        type: POSTS_ERROR,
      })
    }
  }

  const deletePost = async (postId: string) => {
    try {
      await deleteSinglePost(postId)
    } catch (err) {
      dispatch({
        type: POSTS_ERROR,
      })
    }
  }

  const addPostPhoto = async (post: Post, fileState: PhotoFile) => {
    try {
      await uploadPostPhoto(
        post,
        fileState,
      );
    } catch (err) {
      dispatch({
        type: PHOTO_ERROR,
      });
    }
  }

  return <PostContext.Provider
    value={{
      isFetching: state.isFetching,
      posts: state.posts,
      singlePost: state.singlePost,
      getPosts,
      getSinglePost,
      confirmPostReview,
      deletePost,
      addPostPhoto,
      updatePost,
      createJobPost,
    }}>
    {props.children}
  </PostContext.Provider>
}

export default PostState;

PostState.propTypes = {
  children: PropTypes.shape({}),
};

PostState.defaultProps = {
  children: {},
};

// http://localhost:3000/programs/d2fb52b472f24072acc6739126b5b8e1/members/0fa796c4-c39e-403c-8034-01798e035968?reviewId=49f6bd19d9f847058aa8963f986ffc0e
// http://localhost:3000/programs/02919f0aa5b84185bf181116bb88869e/members/e04ceea1-c072-458c-abd7-6d31cc53a228?reviewId=00022c37251443c08cb3641ecfeff15f