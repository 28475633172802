import React, { useReducer } from 'react';
import { eventContext as EventContext } from './eventContext';
import eventReducer from './eventReducer';
import PropTypes from 'prop-types';
import { Event } from '../../models/event';
import { PhotoFile } from '../../models/event';
import { deleteEventPhoto, uploadPhoto, listEvents, getEventById, deleteSingleEvent, editEvent, addEvent, acceptReview } from '../../utils/safeproject-api-client';
import { DELETE_PHOTO, PHOTO_ERROR, EVENTS_ERROR, GET_EVENTS_RESPONSE, GET_SINGLE_EVENT, UPDATE_EVENT, EVENT_CREATE, CLEAR_EVENT, GET_EVENTS_REQUEST, ACCEPT_EVENT } from '../types';
const EventState = (props: { children: any }) => {
  const initialState: any = {
    events: [],
    singleEvent: null,
  };
  const [state, dispatch] = useReducer(eventReducer, initialState);
  const getEvents = async (programId?: string) => {
    dispatch({ type: GET_EVENTS_REQUEST });
    try {
      const result = await listEvents(programId);
      const events = result.items ? result.items : result;
      dispatch({
        type: GET_EVENTS_RESPONSE,
        payload: events,
      });
    } catch (err) {
      dispatch({
        type: EVENTS_ERROR,
      });
    }
  }
  const getSingleEvent = async (eventId: string, programId?: string) => {
    try {
      const eventReturn = await getEventById(eventId, programId);
      dispatch({
        type: GET_SINGLE_EVENT,
        payload: eventReturn,
      })
    } catch (err) {
      dispatch({
        type: EVENTS_ERROR,
      })
    }
  }
  const deleteEvent = async (event: Event) => {
    try {
      await deleteSingleEvent(event)
    } catch (err) {
      dispatch({
        type: EVENTS_ERROR,
      })
    }
  }
  const updateEvent = async (event: Event, programId?: string) => {
    if (programId) {
      try {
        await editEvent(event, programId);
        dispatch({
          type: UPDATE_EVENT,
          payload: event,
        })
      } catch (err) {
        dispatch({
          type: EVENTS_ERROR,
        })
      }
    } else {
      try {
        await editEvent(event);
        dispatch({
          type: UPDATE_EVENT,
          payload: event,
        })
      } catch (err) {
        dispatch({
          type: EVENTS_ERROR,
        })
      }
    }
  }
  const clearEvent = () => {
    dispatch({
      type: CLEAR_EVENT,
    });
  };
  const eventCreate = async (event: Event) => {
    try {
      const response = await addEvent(event);
      dispatch({
        type: EVENT_CREATE,
        payload: response,
      });
    } catch (err) {
      dispatch({
        type: EVENTS_ERROR,
      });
    }
  }
  const addEventPhoto = async (event: Event, fileState: PhotoFile) => {
    try {
      await uploadPhoto(
        event,
        fileState,
      );
    } catch (err) {
      dispatch({
        type: PHOTO_ERROR,
      });
    }
  }
  const deletePhoto = async (event: Event) => {
    try {
      await deleteEventPhoto(event);
      dispatch({
        type: DELETE_PHOTO,
      })
    }
    catch (err) {
      dispatch({
        type: EVENTS_ERROR,
      });
    }
  }
  const confirmEventReview = async (reviewId: string) => {
    return new Promise<void>(async (resolve, reject) => {
      const confirm = {
        status: 'approved'
      }
      try {
        await acceptReview(reviewId)
        dispatch({
          type: ACCEPT_EVENT,
          payload: confirm,
        })
        resolve()
      } catch (err) {
        dispatch({
          type: EVENTS_ERROR,
        })
        reject()
      }
    });
  }

  return <EventContext.Provider
    value={{
      isFetching: state.isFetching,
      getEvents,
      events: state.events,
      singleEvent: state.singleEvent,
      getSingleEvent,
      deleteEvent,
      updateEvent,
      eventCreate,
      confirmEventReview,
      clearEvent,
      addEventPhoto,
      deletePhoto,
    }}>
    {props.children}
  </EventContext.Provider>
}

export default EventState;

EventState.propTypes = {
  children: PropTypes.shape({}),
};

EventState.defaultProps = {
  children: {},
};