import React, { useState, useContext } from 'react';
import {
  Upload, Button, Icon, Modal,
} from 'antd';
import { ProgramContext } from '../../models/program';
import { programContext as _programContext } from '../../contexts/program/programContext';
import { EventContext } from '../../models/event';
import { eventContext as _eventContext } from '../../contexts/event/eventContext';
import { PostContext } from '../../models/post';
import { postContext as _postContext } from '../../contexts/post/postContext';


const AddPhotoModal = ( props: any ) => {
  const [fileState, setFileState] = useState();
  const eventContext: EventContext = useContext(_eventContext);
  const addEventPhoto = eventContext.addEventPhoto!;
  const getSingleEvent = eventContext.getSingleEvent!;
  const programContext: ProgramContext = useContext(_programContext);
  const addProgramPhoto = programContext.addProgramPhoto!;
  const getSingleProgram = programContext.getSingleProgram!;
  const postContext: PostContext = useContext(_postContext);
  const addPostPhoto = postContext.addPostPhoto!;
  const getSinglePost = postContext.getSinglePost!;
  const uploading = async () => {
    if(props?.event){
      await addEventPhoto(
        props.event,
        fileState,
      );
      props?.event?.programId ? setTimeout( async () => {await getSingleEvent(props.event.id, props.event.programId)}, 2000) :
      props?.event?.id ? setTimeout( async () => {await getSingleEvent(props.event.id)}, 2000) : setTimeout(async () => console.log('n/a'), 2000);
    } 
    if(props?.post) {
      await addPostPhoto(
        props.post,
        fileState,
      );
      props?.post?.id ? setTimeout( async () => {await getSinglePost(props.post.id)}, 2000) : setTimeout(async () => console.log('n/a'), 2000);
    }
    else if (props?.program) {
      await addProgramPhoto(
        props.program,
        fileState,
      );
      props?.program?.id ? setTimeout( async () => {await getSingleProgram(props.program.id)}, 2000 ) : setTimeout(async () => console.log('n/a'), 2000);
    }
    props.onCancel();
  }
  const { Dragger } = Upload;
  const uploadPropsDragger = {
    name: 'file',
    multiple: false,
    beforeUpload: ( file: any ) => {
      setFileState( file );
      return false;
    },
    fileState,
  };
  return (
    <div>
    <Modal
      onCancel={props.onCancel}
      visible={props.visible}
      footer={[
        <Button key="submit" type="primary" onClick={uploading}>
          Submit
        </Button>,
      ]}
    >
      <Dragger {...uploadPropsDragger}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
    </Modal >
      </div>
    );
}

export default AddPhotoModal;