const dev = {
  aws: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: "us-east-1",
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: "us-east-1_2SUBXxw7L",
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: "grgrqadung4v6ptnd7hv9j49t",
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      // cookieStorage: {
      // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //   domain: '.yourdomain.com',
      //   // OPTIONAL - Cookie path
      //   path: '/',
      //   // OPTIONAL - Cookie expiration in days
      //   expires: 365,
      //   // OPTIONAL - Cookie secure flag
      //   // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //   secure: true,
      // },
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: "USER_PASSWORD_AUTH"
    },
    API: {
      apiKey:
        "3Hhm83uJOtlPmXT9qcGw4O1MLoKbx4akI1ueONd0", // TODO: read this from a non-versioned env file
      endpoints: [
        {
          name: "SafeProjectAdminRestAPI",
          endpoint: "https://api.dev.safeproject.us"
          // endpoint: "https://api.personal.safeproject.3a-dev-cw.apps.3advance.co"
        }
      ]
    }
  },
  firebase: {
    apiKey: 'AIzaSyAbGh6GOSE_xHxeUM7c5d_vtnnbKMP-fYc',
    authDomain: 'reconnected-dev.firebaseapp.com',
    databaseURL: 'https://reconnected-dev.firebaseio.com',
    projectId: 'reconnected-dev',
    storageBucket: 'reconnected-dev.appspot.com',
  },
  // Settings for accessing HostedUI
  oauth: {
    domain: "safeproject-dev.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: process.env.NODE_ENV === 'production' ? 'https://manage.dev.safeproject.us/login/' : 'http://localhost:3000/login/',
    redirectSignOut: process.env.NODE_ENV === 'production' ? 'https://manage.dev.safeproject.us/' : 'http://localhost:3000/',
    responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
}

const prod = {
  aws: {
    Auth: {
      region: "us-east-1",
      userPoolId: "us-east-1_31rB94gWJ",
      userPoolWebClientId: "7u2up4ei9vi0v4urgsg6c32hhl",
      mandatorySignIn: false,
      authenticationFlowType: "USER_PASSWORD_AUTH"
    },
    API: {
      // apiKey:
      //   "3Hhm83uJOtlPmXT9qcGw4O1MLoKbx4akI1ueONd0", // TODO: read this from a non-versioned env file
      endpoints: [
        {
          name: "SafeProjectAdminRestAPI",
          endpoint: "https://api.prod.safeproject.us"
        }
      ]
    }
  },
  firebase: {
    apiKey: 'AIzaSyB20jyK7L1n0sC-S94_XEwqXk_OhRpa7Sk',
    authDomain: 'reconnected-prod.firebaseapp.com',
    databaseURL: 'https://reconnected-prod.firebaseio.com',
    projectId: 'reconnected-prod',
    storageBucket: 'reconnected-prod.appspot.com',
  },
  // Settings for accessing HostedUI
  oauth: {
    domain: "safeproject-prod.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: 'https://manage.safeproject.us/login/',
    redirectSignOut: 'https://manage.safeproject.us/',
    responseType: "code" // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
}

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default config