import {
  GET_ME,
  SET_AUTH_LOADING,
  SIGN_OUT,
  SET_NAV_ACTIVE_TAB,
} from '../types';

export default (state: any, action: { type: string, payload?: any }) => {
  switch (action.type) {
    case GET_ME:
      return {
        ...state,
        appLoading: false,
        authLoading: false,
        user: action.payload,
        isAuthenticated: true,
      };

    case SIGN_OUT:
      return {
        ...state,
        appLoading: false,
        authLoading: false,
        user: null,
        isAuthenticated: false,
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        authLoading: action.payload,
      };
    case SET_NAV_ACTIVE_TAB:
      return {
        ...state,
        navActiveTab: action.payload,
      };
    default:
      return state;
  }
};
