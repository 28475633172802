import React, { Component } from 'react';
import { Form, Modal, Input, Row, Col, Radio } from 'antd';
const Select: any = require('antd').Select;

interface Props {
  visible: any;
  onCancel: any;
  onOk: any;
  onSubmit: any;
  form: any;
  post?: any;
  lookups?: any;
  creatingJob?: boolean;
}

class PostEditModal extends Component<Props> {
  state = {
    isRemote: 'physical'
  }
  render() {
    const {
      visible,
      onCancel,
      onOk,
      form,
      post,
      lookups,
      creatingJob,
    } = this.props;
    const { getFieldDecorator } = form;
    const { Option } = Select;
    const { TextArea } = Input;
    const setStartingInterests = () => {
      let selectedInterests = [];
      if (post.interests) {
        for (let i = 0; i < post.interests.length; i++) {
          selectedInterests.push(post.interests[i]);
        }
      }
      return selectedInterests;
    }
    const setStartingConnections = () => {
      let selectedConnections = [];
      if (post.connection_interests) {
        for (let i = 0; i < post.connection_interests.length; i++) {
          selectedConnections.push(post.connection_interests[i]);
        }
      }
      return selectedConnections;
    }
    const changingRemote = (e: any) => {
      this.setState({
        isRemote: e.target.value
      })
    }
    const selectMain = () => {
      const interest = lookups.interest;
      const children = [];


      for (let i = 0; i < interest.length; i++) {
        children.push(<Option key={interest[i].name}>{interest[i].name}</Option>);
      }

      return (
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
        >
          {children}
        </Select>);

    }
    const selectMainConnections = () => {
      const connectionType = lookups.connectionType;
      const children = [];
      for (let i = 0; i < connectionType.length; i++) {
        children.push(<Option key={connectionType[i].name}>{connectionType[i].name}</Option>);
      }
      return (
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
        >
          {children}
        </Select>);

    }
    return (
      <Modal
        visible={visible}
        title={post && post.id ? `Edit ${post.name}` : creatingJob ? 'Create New Job' : 'Add a New post'}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Form>
          {post && post.postType === "job opportunity" || creatingJob ? <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="Job Title">
                {getFieldDecorator('name', {
                  initialValue: post ? post?.name : null,
                  rules: [
                    {
                      required: true,
                      message: 'Please input a Name',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Job Type">
                {getFieldDecorator('description', {
                  initialValue: post?.description,
                }
                )(<Input />)}

              </Form.Item>
            </Col>
          </Row>
            :
            <Row>
              <Form.Item label="Post Title">
                {getFieldDecorator('name', {
                  initialValue: post?.name,
                  rules: [
                    {
                      required: true,
                      message: 'Please input a Name',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Row>
          }
          <Form.Item label="Details">
            {getFieldDecorator('body', {
              initialValue: post?.body,
            })(<TextArea />)}
          </Form.Item>
          {post?.postType === 'job opportunity' || creatingJob &&
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label="Company">
                  {getFieldDecorator('company', {
                    initialValue: post ? post?.company : null,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Website">
                  {getFieldDecorator('url', {
                    initialValue: post ? post?.url : null,
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          }{
            creatingJob && <Form.Item label="Is Remote Job?">
              {getFieldDecorator('isRemote', { onChange: changingRemote, initialValue: 'physical' })(
                <Radio.Group style={{ width: '100%' }}>
                  <Row>
                    <Col span={12}><Radio value="virtual">Yes</Radio></Col>
                    <Col span={12}><Radio value="physical">No</Radio></Col>
                  </Row>
                </Radio.Group>
              )}
            </Form.Item>
          }
          {((creatingJob && this.state.isRemote === 'physical') || post?.locationName) && <Form.Item label="Location">
            {getFieldDecorator('locationName', {
              initialValue: post?.locationName,
            })(<Input />)}
          </Form.Item>}

          {!!lookups && lookups.interest && <Form.Item label="Interests">
            {getFieldDecorator('interests', {
              initialValue: post ? setStartingInterests() : [],
            })(
              selectMain()
            )}
          </Form.Item>
          }

          {!!lookups && lookups.connectionType && <Form.Item label="Connections">
            {getFieldDecorator('connection_interests', {
              initialValue: post ? setStartingConnections() : [],
            })(
              selectMainConnections()
            )}
          </Form.Item>

          }
        </Form>

      </Modal>)
  }

}

const postEditForm: any = Form.create({ name: 'post_edit_form' })(
  PostEditModal,
);

export default postEditForm;