import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { PageHeader, Button, Row, Col, Descriptions, Modal, Tabs, Icon } from 'antd';
import { LookupsContext } from '../../models/lookups';
import { EventContext } from '../../models/event';
import AddPhotoModal from '../Photo/AddPhotoModal';
import { eventContext as _eventContext } from '../../contexts/event/eventContext';
import { lookupsContext as _lookupContext } from '../../contexts/lookups/lookupsContext';
import EventCreateModal from './EventCreateModal';
import { ReviewStatus } from '../../models/reviews';
import queryString from 'query-string';
import ProgramDetail from '../Programs/ProgramDetail';
import ChatComponent from '../Chat/ChatComponent';

const EventDetail: React.FC<{}> = (props: any) => {
  const eventContext: EventContext = useContext(_eventContext);
  const clearEvent = eventContext.clearEvent!;
  const deletePhoto = eventContext.deletePhoto!;
  const getSingleEvent = eventContext.getSingleEvent!;
  const [uploadActive, setUploadActive] = useState(false);
  const deleteEvent = eventContext.deleteEvent!;
  const updateEvent = eventContext.updateEvent!;
  const [modalVisible, setModalVisible] = useState(false);
  const { singleEvent } = eventContext;
  const [back, setBack] = useState(false);

  const { location } = props;
  const { search } = location;
  const queryParams = queryString.parse(search);
  const { reviewId } = queryParams;
  const confirmEventReview = eventContext.confirmEventReview!;
  const lookupsContext: LookupsContext = useContext(_lookupContext);
  const getLookups = lookupsContext.getLookupsFromApi!;
  const { lookupsCollection } = lookupsContext;
  const { TabPane } = Tabs;
  const [infoFormRef, setInfoFormRef] = useState({
    props: {
      form: { validateFields: (cb: Function) => { }, resetFields: () => { } },
    },
  });
  useEffect(() => {
    getLookups();
    if (props.match.params.programId) {
      getSingleEvent(props.match.params.eventId, props.match.params.programId);
      return () => {
        clearEvent();
      };
    }
    else {
      getSingleEvent(props.match.params.eventId);

      return () => {
        clearEvent();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInfoModalVisible = (boolVal: React.SetStateAction<boolean>) => {
    setModalVisible(boolVal);
  }
  const handleCancel = () => {
    setModalVisible(false);
  }
  const renderBack = () => {
    if (back) {
      return <Redirect to="/events" />;
    }
    return null;
  };
  const handleDeleteEvent = () => {
    deleteEvent(singleEvent);
    setTimeout(() => setBack(true), 1000);
  }
  const handleDeletePhoto = async () => {
    await deletePhoto(singleEvent);
  }
  const { confirm } = Modal
  const showDeletePhoto = () => {
    confirm({
      title: 'Are you sure delete this Photo?',
      content: null,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      className: 'delete-confirm-modal',
      onOk() {
        handleDeletePhoto();
      },
      onCancel() {
      },
    });
  }
  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete this event?',
      content: null,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      className: 'delete-confirm-modal',
      onOk() {
        handleDeleteEvent();
      },
      onCancel() {
      },
    });
  }
  const renderEventHeader = () => {
    return (!!singleEvent &&
      <PageHeader
        style={{ backgroundColor: '#fff' }}
        onBack={() => setBack(true)}
        title={singleEvent!.name}
        subTitle={
          singleEvent && reviewId && singleEvent.status === ReviewStatus.pending
            ? <Button onClick={confirmEvent} key="4">Confirm Event</Button>
            : singleEvent ? singleEvent.status : ''
        }
        extra={[
          <Button onClick={() => setInfoModalVisible(true)} key="3">
            Edit Details
          </Button>,
          <Button
            type='danger'
            onClick={showDeleteConfirm}
            key="4"
          >
            Delete Event
          </Button>,
        ]}>
        <Content extra={null}>{renderEventBody()}</Content>
      </PageHeader>
    )
  }
  const Content = ({ children, extra }: { children: any; extra: any }) => {
    return (
      <div className="content">
        <div className="main">{children}</div>
        <div className="extra">{extra}</div>
      </div>
    );
  };
  const getLocalTime = (isoTime: any) => {
    let returnDate = new Date(isoTime);
    return `${returnDate.toLocaleDateString('en-US', {

      weekday: 'long',
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    })}`
    // return date;
  }
  // const detailArrayToStringConnections = ( arrToParse: string | any[] ) => {
  //   let returnString = '';
  //   for ( let i = 0; i < arrToParse.length; i++ ) {
  //     if ( i + 1 < arrToParse.length ) {
  //       returnString += `${arrToParse[i].name}, `;
  //     }
  //     else {
  //       returnString += `${arrToParse[i].name}`;
  //     }

  //   }
  //   return returnString;
  // }
  const detailArrayToString = (arrToParse: string | any[]) => {
    let returnString = '';
    for (let i = 0; i < arrToParse.length; i++) {
      if (i + 1 < arrToParse.length) {
        returnString += `${arrToParse[i]}, `;
      }
      else {
        returnString += `${arrToParse[i]}`;
      }

    }
    return returnString;
  }
  const confirmEvent = async () => {

    try {
      await confirmEventReview(reviewId);

    } catch (error) {

    }

  }
  const handleUploadModal = () => {
    setUploadActive(!uploadActive);
  };
  const onCancelUpload = () => {
    setUploadActive(false);
  }
  const eventPhoto = () => {
    if (singleEvent?.photo) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto', paddingTop: '30px' }}>
          <img
            style={{ display: 'block', margin: 'auto', width: '80%', maxHeight: '100vh', objectFit: 'cover' }}
            src={singleEvent.photo.url}
            alt={singleEvent.photo.title ? singleEvent.photo.title : 'untitled photo fot event'}
          />
          <Button
            style={{ margin: '1rem' }}
            type='danger'
            onClick={showDeletePhoto}
            key="4"
          >
            Delete Photo
          </Button>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto', paddingTop: '30px' }} >
        <Button className="upload-button" onClick={handleUploadModal}>
          <Icon type="upload" />
          {' '}
          Click to Upload
        </Button>
      </div>
    );
  }

  const saveInfoFormRef = (formRef: any) => setInfoFormRef(formRef);
  const renderEventBody = (column = 1) => {
    return !!singleEvent && (
      <div style={{ paddingTop: '20px' }}>
        <Row gutter={16}>
          <Col span={5} >
            <Descriptions size="small" column={column}>
              {!!singleEvent.city}
              <Descriptions.Item label="Event Type">
                {singleEvent.type?.charAt(0).toUpperCase()}{singleEvent!.type?.slice(1)}
              </Descriptions.Item>
              {singleEvent && singleEvent.programName && <Descriptions.Item label="Program Hosting Event">
                {singleEvent!.programName || 'N/A'}
              </Descriptions.Item>}

              <Descriptions.Item label="Address">
                {singleEvent!.address || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="City, State & Zip">
                { singleEvent.city ? `${singleEvent!.city} ${singleEvent!.state} ${singleEvent.zip}` : 'N/A' }
              </Descriptions.Item>
              <Descriptions.Item label="Event Status">
                {singleEvent!.eventStatus?.toUpperCase() || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Location Name">
                {singleEvent?.locationName || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Location Type">
                {singleEvent?.locationType?.toUpperCase() || 'N/A'}
              </Descriptions.Item>
            </Descriptions>{' '}
          </Col>
          <Col span={5}>
            <Descriptions size="small" column={column}>
              {!!singleEvent.startDateTime}
              <Descriptions.Item label="Start Time">

                {getLocalTime(singleEvent.startDateTime)}

              </Descriptions.Item>
              {!!singleEvent.endDateTime && (
                <Descriptions.Item label="End Time">
                  {getLocalTime(singleEvent.endDateTime)}
                </Descriptions.Item>
              )}
              {!!singleEvent.location && (
                <Descriptions.Item label="Location">
                  {singleEvent.locationName && singleEvent.locationName.trim().length > 0 ? singleEvent.locationName : 'N/A'}
                </Descriptions.Item>
              )}
              {!!singleEvent.interests && (
                <Descriptions.Item label="Interests">
                  {detailArrayToString(singleEvent.interests)}
                </Descriptions.Item>
              )}
              {!!singleEvent.connection_interests && (
                <Descriptions.Item label="Connection Types">
                  {detailArrayToString(singleEvent.connection_interests)}
                </Descriptions.Item>
              )}
            </Descriptions>{' '}
          </Col>
          <Col offset={4} span={5}>
            <Descriptions size="small" column={column}>
              <Descriptions.Item label="Created On">
                {getLocalTime(singleEvent.createDate)}
              </Descriptions.Item>
              <Descriptions.Item label="Created By User Name">
                {singleEvent.createdByUser.name}
              </Descriptions.Item>
              <Descriptions.Item label="Created By User Email">
                {singleEvent.createdByUser.email}
              </Descriptions.Item>
            </Descriptions>{' '}
          </Col>
        </Row>
      </div>
    )
  };
  const handleOk = () => {
    const { form } = infoFormRef.props;

    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      const interestArr: any[] = [];
      if (lookupsCollection) {
        for (let i = 0; i < lookupsCollection?.interest.length; i++) {
          if (values.interests.includes(lookupsCollection?.interest[i].name)) {
            interestArr.push(lookupsCollection?.interest[i].name);
          }
        }
      }
      const connectionArr: any[] = [];
      if (lookupsCollection) {
        for (let i = 0; i < lookupsCollection?.connectionType.length; i++) {
          if (values.connectionTypes.includes(lookupsCollection?.connectionType[i].name)) {
            connectionArr.push(lookupsCollection?.connectionType[i].name);
          }
        }
      }
      let dateTimeStartArr = [];
      let dateTimeEndArr = [];
      if (values.startStopDate) {
        let dateStringStart = values.startStopDate[0].toISOString().split('T');
        let timeStringStart = values.startTime.toISOString().split('T');
        dateTimeStartArr.push(dateStringStart[0]);
        dateTimeStartArr.push(['T']);
        dateTimeStartArr.push(timeStringStart[1]);
        let dateStringEnd = values.startStopDate[1].toISOString().split('T');
        let timeStringEnd = values.endTime.toISOString().split('T');
        dateTimeEndArr.push(dateStringEnd[0]);
        dateTimeEndArr.push(['T']);
        dateTimeEndArr.push(timeStringEnd[1]);
      }
      form.resetFields();
      let updatedEvent = {
        name: values.name,
        type: values.type ? values.type : 'local',
        startDateTime: values.startStopDate ? dateTimeStartArr.join('') : null,
        endDateTime: values.startStopDate ? dateTimeEndArr.join('') : null,
        locationName: values.locationName,
        city: values.city,
        state: values.state,
        zip: values.zip,
        address: values.address,
        interests: interestArr,
        connection_interests: connectionArr,
      };
      const eventToSend = {
        ...singleEvent,
        ...updatedEvent,
      };
      if (props.match.params.programId) {
        updateEvent(eventToSend, props.match.params.programId);
      } else {
        updateEvent(eventToSend);
      }
      setInfoModalVisible(false);
    });
  };
  return (
    singleEvent ? (
      <div style={{ padding: '0rem 1rem' }}>
        <AddPhotoModal event={singleEvent} visible={uploadActive} onCancel={onCancelUpload} />
        {renderBack()}
        {renderEventHeader()}
        <Tabs>
          {/* <TabPane tab="RSVP's" key="1members">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Icon type="clock-circle" style={{ fontSize: '2rem', margin: '6rem 0 1rem 0' }} />
              <div>Coming Soon</div>
            </div>
          </TabPane> */}
          {/* {!!singleEvent && singleEvent.programId && <TabPane tab="Program Details" key="2program">
            <ProgramDetail programId={singleEvent.programId} />
          </TabPane>} */}
          {/* {!!singleEvent && singleEvent.programId && <TabPane tab="Chat" key="3chat">
            <ChatComponent event={singleEvent} />
          </TabPane>} */}
          <TabPane tab='Event Photo' key='photo'>
            <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>{eventPhoto()}</div>
          </TabPane>
        </Tabs>

        <EventCreateModal
          wrappedComponentRef={saveInfoFormRef}
          event={singleEvent}
          onSubmit={handleOk}
          visible={modalVisible}
          onCancel={handleCancel}
          lookups={lookupsCollection}
          onOk={handleOk} />
      </div>) :
      (null)
  );
}

export default EventDetail;