/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../../models/auth';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import Loading from './Loading';

const PublicRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const authContext: AuthContext = useContext(_authContext);
  const { appLoading, authLoading, isAuthenticated } = authContext;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (appLoading || authLoading) {
          return <Loading />;
        }
        if (isAuthenticated) {
          return <Redirect to="/members" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default PublicRoute;
