export const GET_ME = 'GET_ME';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const GET_PROGRAMS_REQUEST = 'GET_PROGRAMS_REQUEST';
export const GET_PROGRAMS_RESPONSE = 'GET_PROGRAMS_RESPONSE';
export const PROGRAM_ERROR = 'PROGRAM_ERROR';
export const GET_SINGLE_PROGRAM = 'GET_SINGLE_PROGRAM';
export const PROGRAM_CREATE = 'PROGRAM_CREATE';
export const UPDATE_PROGRAM = 'UPDATE_PROGRAM';
export const GET_LOOKUPS_RESPONSE = 'GET_LOOKUPS_RESPONSE';
export const GET_LOOKUPS_REQUEST = 'GET_LOOKUPS_REQUEST';
export const LOOKUP_ERROR = 'LOOKUP_ERROR';
export const LOOKUP_CREATE = 'LOOKUP_CREATE';
export const LOOKUP_DELETE = 'LOOKUP_DELETE';
export const GET_ADMIN_USERS_RESPONSE = 'GET_ADMIN_USERS_RESPONSE';
export const GET_ADMIN_USERS_REQUEST = 'GET_ADMIN_USERS_REQUEST';
export const USER_ERROR = 'USER_ERROR';
export const ADD_ADMIN_USER = 'ADD_ADMIN_USER';
export const GET_EVENTS_RESPONSE = 'GET_EVENTS_RESPONSE';
export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const EVENTS_ERROR = 'EVENTS_ERROR';
export const GET_SINGLE_EVENT = 'GET_SINGLE_EVENT';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const EVENT_CREATE = 'EVENT_CREATE';
export const MEMBER_ERROR = 'MEMBER_ERROR';
export const GET_MEMBERS_RESPONSE = 'GET_MEMBERS_RESPONSE';
export const GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST';
export const REVIEWS_ERROR = 'REVIEWS_ERROR';
export const GET_REVIEWS_RESPONSE = 'GET_REVIEWS_RESPONSE';
export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST';
export const GET_SINGLE_MEMBER = 'GET_SINGLE_MEMBER';
export const ACCEPT_MEMBER = 'ACCEPT_MEMBER';
export const CLEAR_PROGRAM = 'CLEAR_PROGRAM';
export const CLEAR_MEMBER = 'CLEAR_MEMBER';
export const CLEAR_EVENT = 'CLEAR_EVENT';
export const LOOKUP_UPDATE = 'LOOKUP_UPDATE';
export const ACCEPT_EVENT = 'ACCEPT_EVENT';
export const SET_NAV_ACTIVE_TAB = 'SET_NAV_ACTIVE_TAB';
export const PHOTO_ERROR = 'PHOTO_ERROR';
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const DELETE_PROGRAM_PHOTO = 'DELETE_PROGRAM_PHOTO';
export const ADD_PHOTO = 'ADD_PHOTO';
export const POSTS_ERROR = 'POSTS_ERROR';
export const GET_POSTS_RESPONSE = 'GET_POSTS_RESPONSE';
export const GET_POSTS_REQUEST = 'GET_POSTS_REQUEST';
export const GET_SINGLE_POST = 'GET_SINGLE_POST';
export const ACCEPT_POST = 'ACCEPT_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const GET_CHATS_REQUEST = 'GET_CHATS_REQUEST';
export const GET_CHATS_RESPONSE = 'GET_CHATS_RESPONSE';
export const CHATS_ERROR = 'CHATS_ERROR';
export const GET_MEMBER_GROUPS = 'GET_MEMBER_GROUPS';
export const SET_AUTH = 'SET_AUTH';