import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../models/auth';
import { authContext as _authContext } from '../../contexts/auth/authContext';

import { Spin } from 'antd';

const Loading: React.FC<{}> = () => {
  const authContext: AuthContext = useContext(_authContext);
  const getMe = authContext.getMe!;
  const { authLoading } = authContext;

  useEffect(() => {
    if (!authLoading) {
      getMe();
    }
  }, [authLoading, getMe]);

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '40vh',
    }}
    >
      <Spin />
    </div>
  )
};

export default Loading;
