import {
  GET_PROGRAMS_REQUEST,
  GET_PROGRAMS_RESPONSE,
  GET_SINGLE_PROGRAM,
  PROGRAM_CREATE,
  UPDATE_PROGRAM,
  CLEAR_PROGRAM,
  DELETE_PROGRAM_PHOTO,
} from '../types';

export default ( state: any, action: { type: string; payload?: any } ) => {
  switch ( action.type ) {
    case GET_PROGRAMS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PROGRAMS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        programs: action.payload,
      };
    case GET_SINGLE_PROGRAM: {
      return {
        ...state,
        singleProgram: action.payload,
      }
    }
    case PROGRAM_CREATE: {
      return {
        ...state,
        programs: [action.payload, ...state.programs],
      }
    }
    case UPDATE_PROGRAM: {
      return {
        ...state,
        singleProgram: action.payload,
      }
    }
    case CLEAR_PROGRAM: {
        return {
          ...state,
          singleProgram: null,
        }
      }
    case DELETE_PROGRAM_PHOTO: {
      return {
        ...state,
        singleProgram: { ...state.singleProgram, photo: null}
      }
    }
    default:
      return state;
  }
};
