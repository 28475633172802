import React, { useReducer } from 'react';
import { memberContext as MemberContext } from './memberContext';
import memberReducer from './memberReducer';
import PropTypes from 'prop-types';
// import * as myData from '../../utils/programs.json';
import { listMembers, getMemberById, acceptReview, deleteSingleMember } from '../../utils/safeproject-api-client';
import { GET_MEMBERS_RESPONSE, MEMBER_ERROR, GET_SINGLE_MEMBER, ACCEPT_MEMBER, CLEAR_MEMBER, GET_MEMBERS_REQUEST } from '../types';

const MemberState = (props: { children: any }) => {
  //   const dataList = myData;
  const initialState: any = {
    members: [],
    singleMember: null,
  };
  const [state, dispatch] = useReducer(memberReducer, initialState);
  const getMembers = async (memberName?: string, programId?: string) => {
    dispatch({ type: GET_MEMBERS_REQUEST });
    try {
      const members = await listMembers(memberName, programId);
      dispatch({
        type: GET_MEMBERS_RESPONSE,
        payload: members.items ? members.items : members,
      });
    } catch (err) {
      dispatch({
        type: MEMBER_ERROR,
      });
    }
  }
  const getSingleMember = async (memberId: string, programId: string) => {
    try {
      const member = await getMemberById(memberId, programId);
      dispatch({
        type: GET_SINGLE_MEMBER,
        payload: member,
      })

    } catch (err) {
      dispatch({
        type: MEMBER_ERROR
      })
    }
  }
  const deleteMember = async ( programId: string, memberId: string ) => {
    try {
      await deleteSingleMember( programId, memberId );

    } catch ( err ) {
      dispatch( {
        type: MEMBER_ERROR,
      } );
    }
  }
  const confirmMemberReview = async (reviewId: string) => {
    return new Promise<void>(async (resolve, reject) => {
      const confirm = {
        status: 'approved'
      }
      try {
        await acceptReview(reviewId)
        dispatch({
          type: ACCEPT_MEMBER,
          payload: confirm,
        })
        resolve()
      } catch (err) {
        dispatch({
          type: MEMBER_ERROR,
        })
        reject()
      }
    });
  }
  const clearMember = () => {
    dispatch({
      type: CLEAR_MEMBER,
    });
  };
  return <MemberContext.Provider
    value={{
      isFetching: state.isFetching,
      members: state.members,
      getMembers,
      getSingleMember,
      singleMember: state.singleMember,
      confirmMemberReview,
      clearMember,
      deleteMember,
    }}>
    {props.children}
  </MemberContext.Provider>
}

export default MemberState;

MemberState.propTypes = {
  children: PropTypes.shape({}),
};

MemberState.defaultProps = {
  children: {},
};