import React, { useEffect, useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { EventContext, Event } from '../../models/event';
import { LookupsContext } from '../../models/lookups';
import { eventContext as _eventContext } from '../../contexts/event/eventContext';
import { lookupsContext as _lookupContext } from '../../contexts/lookups/lookupsContext';
import { Table, Button, Input, DatePicker } from 'antd';
import { Program } from '../../models/program';
import EventCreateModal from './EventCreateModal';
import { AuthContext } from '../../models/auth';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import moment from 'moment';
import { onCSVExport } from '../../utils/utils';


const EventsList = ({ program, searchKey, searchValue }: { program?: Program, searchKey?: string, searchValue?: string }) => {
  const [redirectToEvent, setRedirectToEvent] = useState<any>();
  const [redirectToReviews, setRedirectToReviews] = useState<any>();
  const dateFormatForPicker = 'YYYY-MM-DD';
  const [modalVisible, setDetailsModalVisible] = useState(false);
  const eventContext: EventContext = useContext(_eventContext);
  const clearEvent = eventContext.clearEvent!;
  const lookupsContext: LookupsContext = useContext(_lookupContext);
  const getEvents = eventContext.getEvents!;
  const getLookups = lookupsContext.getLookupsFromApi!;
  // const [startDateSearch,] = useState(moment().format(dateFormatForPicker));
  const [startDateSearch,] = useState(moment().subtract(180, 'days').format(dateFormatForPicker));
  const [endDateSearch,] = useState(moment().add(30, 'days').format(dateFormatForPicker));
  const eventCreate = eventContext.eventCreate!;
  const { events, isFetching } = eventContext;
  const { lookupsCollection } = lookupsContext;
  const authContext: AuthContext = useContext(_authContext);
  const setNavActiveTab = authContext.setNavActiveTab!;
  const [searchParams, setSearchParams] = useState({
    startDateTime: startDateSearch,
    endDateTime: endDateSearch,
  })
  const dateFormat = (isoTime: any) => {
    let returnDate = new Date(isoTime);
    return `${returnDate.toLocaleDateString('en-US', {
      weekday: 'long',
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    })}`
  }

  useEffect(() => {
    getEvents(searchParams, program?.id);
    getLookups();
    return () => {
      clearEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { Search } = Input;
  const { RangePicker } = DatePicker;
  const [infoFormRef, setInfoFormRef] = useState({
    props: {
      form: { validateFields: (cb: Function) => { }, resetFields: () => { } },
    },
  });
  const formattedAddress = (event: Event) => {
    let returnString = ''
    if (event.address) {
      if (event.city || event.state) {
        returnString += `${event.address}, `;
      } else {
        returnString += `${event.address}`;
      }
    }
    if (event.city) {
      returnString += `${event.city} `;
    }
    if (event.state) {
      returnString += `${event.state} `;
    }
    return returnString;
  }
  const formattedEvents = events?.map(event => (
    {
      ...event,
      startDateTime: event.startDateTime ? dateFormat(event.startDateTime) : '',
      endDateTime: event.endDateTime ? dateFormat(event.endDateTime) : '',
      createDate: event.createDate ? dateFormat(event.createDate) : '',
      address: formattedAddress(event)
    }));
  const handleCancel = () => {
    setDetailsModalVisible(false);
  }
  const saveInfoFormRef = (formRef: any) => setInfoFormRef(formRef);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Location',
      dataIndex: 'locationName',
      key: 'locationName',
    },
    {
      title: 'City',
      dataIndex: 'geo.city',
      key: 'city',
      render: (text: string, record: Event) => record && record.geo && record.geo.city && record?.geo?.city?.length > 0 ? `${record.geo.city}` : 'N/A',
    },
    {
      title: 'State',
      dataIndex: 'geo.state',
      key: 'state',
      render: (text: string, record: Event) => record && record.geo && record.geo.state && record?.geo?.state?.length > 0 ? `${record.geo.state}` : 'N/A',
    },
    {
      title: 'Start',
      dataIndex: 'startDateTime',
      key: 'startTime',
    },
    {
      title: 'End',
      dataIndex: 'endDateTime',
      key: 'endTime',
    },
    {
      title: 'Created On',
      dataIndex: 'createDate',
      key: 'createDate',
    },
    {
      title: 'Created By User Name',
      dataIndex: 'createdByUser.name',
      key: 'createdByUserName',
    },
    {
      title: 'Created By User Email',
      dataIndex: 'createdByUser.email',
      key: 'createdByUserEmail',
    },
    {
      title: 'Local/National',
      dataIndex: 'type',
      key: 'type',
      filters: [
        {
          text: 'Local',
          value: 'local',
        },
        {
          text: 'National',
          value: 'national',
        },
      ],
      filterMultiple: false,
      onFilter: (value: string, record: Event) => record.type!.indexOf(value) === 0,
      render: (text: string, record: Event) => (record.type ? `${record.type!.charAt(0).toUpperCase()}${record.type!.slice(1)}` : ''),
    }
  ];
  const handleOk = () => {
    const { form } = infoFormRef.props;

    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      const interestArr: any[] = [];
      if (lookupsCollection) {
        for (let i = 0; i < lookupsCollection?.interest.length; i++) {
          if (values.interests.includes(lookupsCollection?.interest[i].name)) {
            interestArr.push(lookupsCollection?.interest[i].name);
          }
        }
      }
      const connectionArr: any[] = [];
      if (lookupsCollection) {
        for (let i = 0; i < lookupsCollection?.connectionType.length; i++) {
          if (values.connectionTypes.includes(lookupsCollection?.connectionType[i].name)) {
            connectionArr.push(lookupsCollection?.connectionType[i].name);
          }
        }
      }
      let dateTimeStartArr = [];
      let dateTimeEndArr = [];
      let startTimeObject = {};
      let endTimeObject = {};
      if (values.startStopDate) {
        const tzOffset = (new Date()).getTimezoneOffset() * 60000;
        let dateStringStart = values.startStopDate[0].toISOString().split('T');
        let timeStringStart = (new Date(values.startTime - tzOffset)).toISOString().split('T');
        startTimeObject = {
          date: dateStringStart[0],
          time: timeStringStart[1].substr(0, 5),
          tz: values.timezone ? values.timezone : 'America/New_York',
        }
        dateTimeStartArr.push(dateStringStart[0]);
        dateTimeStartArr.push(['T']);
        dateTimeStartArr.push(timeStringStart[1]);
        let dateStringEnd = values.startStopDate[1].toISOString().split('T');
        let timeStringEnd = (new Date(values.endTime - tzOffset)).toISOString().split('T');
        endTimeObject = {
          date: dateStringEnd[0],
          time: timeStringEnd[1].substr(0, 5),
          tz: values.timezone ? values.timezone : 'America/New_York',
        }
        dateTimeEndArr.push(dateStringEnd[0]);
        dateTimeEndArr.push(['T']);
        dateTimeEndArr.push(timeStringEnd[1]);
      }
      // form.resetFields();
      const updatedEvent = {
        name: values.name,
        type: values.type ? values.type : 'local',
        startDateObject: startTimeObject ? startTimeObject : null,
        endDateObject: endTimeObject ? endTimeObject : null,
        startDateTime: values.startStopDate ? dateTimeStartArr.join('') : null,
        endDateTime: values.startStopDate ? dateTimeEndArr.join('') : null,
        locationName: values.locationName,
        city: values.city,
        state: values.state,
        zip: values.zip,
        address: values.address,
        interests: interestArr,
        locationType: values.isRemote,
        connection_interests: connectionArr,
      };

      if (program) {
        Object.assign(updatedEvent, { programId: program.id });
      }
      if (!!values.startStopTime && !!values.startStopTime[0] && !!values.startStopTime[0]._d) {
        updatedEvent!.startDateTime = values.startStopTime[0]._d.toISOString()
      }
      if (!!values.startStopTime && !!values.startStopTime[1] && !!values.startStopTime[1]._d) {
        updatedEvent!.endDateTime = values.startStopTime[1]._d.toISOString()
      }
      eventCreate(updatedEvent);
      setDetailsModalVisible(false);
      setTimeout(() => { setRedirectToReviews(true); setNavActiveTab('reviews'); }, 2000);
    });
  };
  const renderRedirectToReviews = () => {
    if (redirectToReviews) {
      return <Redirect to={'/reviews'} />;
    }
    return null;
  };
  const renderRedirectToEvent = () => {
    if (redirectToEvent && redirectToEvent!.programId) {
      return <Redirect to={`/programs/${redirectToEvent!.programId}/events/${redirectToEvent!.id}`} />;
    } else if (redirectToEvent && redirectToEvent!.id) {
      return <Redirect to={`/events/${redirectToEvent!.id}`} />;
    }
    return null;
  };

  const eventProgramSearchInput = (e: { target: { value: any; }; }) => {
    let searchParamsAdded = {
      ...searchParams,
      programName: e.target.value,
    };
    setSearchParams(searchParamsAdded);
  }
  const eventLocationSearchInput = (e: { target: { value: any; }; }) => {
    let searchParamsAdded = {
      ...searchParams,
      search: e.target.value,
    };
    setSearchParams(searchParamsAdded);
  }
  const onChangeDateSearch = (value: any, dateString: any) => {
    const searchParamsAdded = {
      ...searchParams,
      startDateTime: dateString[0],
      endDateTime: dateString[1],
    };
    setSearchParams(searchParamsAdded);
  }
  const searchEvents = () => {
    getEvents(searchParams);
  }

  const defaultDateRange = ([moment(startDateSearch, dateFormatForPicker), moment(endDateSearch, dateFormatForPicker)] || []) as any;

  return (
    <div className="list-wrapper">
      {renderRedirectToEvent()}
      {renderRedirectToReviews()}
      {/* {!program && <Search
        placeholder="Associated Program"
        allowClear={true}
        enterButton="Search"
        onChange={eventProgramSearchInput}
        onSearch={searchEvents}
        style={{ width: 250, marginBottom: '1rem', marginRight: '1rem' }}
      />} */}
      <Search
        placeholder="Event Location"
        // allowClear={true}
        enterButton="Search"
        onChange={eventLocationSearchInput}
        onSearch={searchEvents}
        style={{ width: 250, marginBottom: '1rem', marginRight: '1rem' }}
      />
      <RangePicker
        showTime={{ format: 'HH:mm' }}
        allowClear={false}
        style={{ width: 250, marginBottom: '1rem', marginRight: '1rem' }}
        format="YYYY-MM-DD"
        defaultValue={defaultDateRange}
        onChange={onChangeDateSearch}
        onOk={searchEvents}
      />
      <Button

        className="safeProject-action-button"
        type="primary"
        style={{ float: 'right' }}
        onClick={() => setDetailsModalVisible(true)}
      >
        {program ? 'Add Associated Event' : 'Add Event'}
      </Button>
      <Button 
        onClick={onCSVExport.bind(null, 'Events', events, columns)}
        style={{marginLeft: '1rem', marginRight: '1rem' }}
      >
        Export to CSV
      </Button>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: () => setRedirectToEvent(record),
          };
        }}
        dataSource={formattedEvents}
        columns={columns}
        rowKey="id"
        loading={isFetching}
      />
      <EventCreateModal
        wrappedComponentRef={saveInfoFormRef}
        onSubmit={handleOk}
        visible={modalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        lookups={lookupsCollection}
      />
    </div>
  );

}

export default EventsList