import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { authContext as AuthContext } from "./authContext";
import authReducer from "./authReducer";

import { getUserProfile } from "../../utils/safeproject-api-client";

import {
  GET_ME,
  SIGN_OUT,
  SET_AUTH_LOADING,
  SET_NAV_ACTIVE_TAB,
} from "../types";
import firebase from 'firebase';

const AuthState = (props: { children: any }) => {
  const initialState: any = {
    isAuthenticated: false,
    appLoading: true,
    authLoading: false,
    user: null,
    navActiveTab: "",
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const initAuth = async () => {
    try {
      await firebase.auth().onAuthStateChanged(async (auth) => {
        dispatch({ type: SET_AUTH_LOADING })
        if (auth) {
          getMe()
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getMe = async () => {
    try {
      const userProfile = await getUserProfile();
      if (userProfile.role === "admin") {
        dispatch({
          type: GET_ME,
          payload: userProfile,
        });
      } else {
        // this user should not be here
        // await firebase.auth().signOut();
        dispatch({
          type: SIGN_OUT,
        });
      }
    } catch (err) {
      dispatch({
        type: SIGN_OUT,
      });
    }
  };

  const signOut = async () => {
    dispatch({
      type: SIGN_OUT,
    });
  };

  const setAuthLoading = async (loading: boolean) => {
    dispatch({
      type: SET_AUTH_LOADING,
      payload: loading,
    });
  };

  const setNavActiveTab = async (tab: string) => {
    dispatch({
      type: SET_NAV_ACTIVE_TAB,
      payload: tab,
    });
  };
  useEffect(() => {
    initAuth()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        getMe,
        signOut,
        setAuthLoading,
        setNavActiveTab,
        navActiveTab: state.navActiveTab,
        user: state.user,
        appLoading: state.appLoading,
        authLoading: state.authLoading,
        isAuthenticated: state.isAuthenticated,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;

AuthState.propTypes = {
  children: PropTypes.shape({}),
};

AuthState.defaultProps = {
  children: {},
};
