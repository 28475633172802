/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../../models/auth';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import Loading from './Loading';

const PrivateRoute: React.FC<any> = ({ component: Component, ...rest }) => {
  const authContext: AuthContext = useContext(_authContext);
  const { isAuthenticated, appLoading } = authContext;
  // useEffect(() => {
  //   if(!isAuthenticated){

  //   }
  // })
  return (
    <Route
      {...rest}
      render={(props) => {
        if (appLoading) {
          return <Loading />;
        }
        if (!isAuthenticated) {
          return <Redirect to="/" />;
        }
        return <Component {...props} {...rest} />;
      }}
    />
  );
};

export default PrivateRoute;