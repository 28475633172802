import { GET_ADMIN_USERS_RESPONSE, ADD_ADMIN_USER, GET_ADMIN_USERS_REQUEST } from '../types';

export default ( state: any, action: { type: string; payload?: any } ) => {
  switch ( action.type ) {
    case GET_ADMIN_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ADMIN_USERS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        adminUsers: action.payload,
      };
    case ADD_ADMIN_USER:
      return {
        state,
        adminUsers: [action.payload, ...state.adminUsers]
      }
    default:
      return state;
  }
};
